import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import { Line } from 'react-chartjs-2'
import { apiRequestGet } from '../utils/api'

export const ContributorLeaderboard = () => {
    document.title = 'AutoIMDb | Contributor Leaderboard'

    const [contributors, setContributors] = useState([])
    const [users, setUsers] = useState([])
    const [autoCompleteValue, setAutoCompleteValue] = useState([])
    const [datasets, setDatasets] = useState([])
    const [labels, setLabels] = useState([])

    // * Fetch all the data required to make the leaderboard
    useEffect(() => {
        async function retrieveContributors() {
            const req = await apiRequestGet('/contributor-leaderboard-data')

            setContributors(req)
        }
        retrieveContributors()
    }, [])

    // * Set the autocomplete content
    useEffect(() => {
        if (contributors === undefined || contributors.length === 0) return
        setUsers(
            contributors
                .at(-1)
                .users.map((contributor) => contributor.username)
                .slice(0, 1000)
        )
    }, [contributors, autoCompleteValue])

    useEffect(() => {
        if (
            autoCompleteValue === undefined ||
            contributors === undefined ||
            contributors.length === 0
        )
            return
        let labels = []
        let datasets = []
        const colors = [
            'rgba(255, 99, 132, 1)',
            'rgba(51, 255, 51, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(255, 0, 127, 1)',
            'rgba(204, 102, 0, 1)',
            'rgba(2, 8, 189, 1)',
            'rgba(190, 7, 222, 1)',
            'rgba(240, 0, 8, 1)',
            'rgba(99, 0, 230, 1)',
        ]
        let earliestIndex = -1

        for (let i = 0; i < contributors.length; i++) {
            const usernames = contributors[i].users.map((user) => user.username)
            for (let j = 0; j < autoCompleteValue.length; j++) {
                if (usernames.includes(autoCompleteValue[j])) {
                    earliestIndex = i
                    break
                }
            }
            if (earliestIndex !== -1) break
        }
        // * Set labels
        for (let i = earliestIndex; i < contributors.length; i++)
            labels.push(contributors.at(i).label)

        // * Set datasets
        for (let i = 0; i < autoCompleteValue.length; i++) {
            const arrayOfValues = []
            const username = autoCompleteValue.at(i)
            for (let j = earliestIndex; j < contributors.length; j++) {
                const user = contributors
                    .at(j)
                    .users.find((user) => user.username === username)
                if (user === undefined) arrayOfValues.push(0)
                else arrayOfValues.push(user.items)
            }

            datasets.push({
                label: username,
                data: arrayOfValues,
                borderColor: colors[i],
                cubicInterpolationMode: 'monotone',
            })
        }

        setLabels(labels)
        setDatasets(datasets)
    }, [autoCompleteValue, contributors])

    const options = {
        responsive: true,
        scales: {
            y: {
                callback(value) {
                    return Number(value).toLocaleString('en')
                },
            },
        },
    }

    return (
        <Stack
            spacing={1}
            sx={{ width: '100%', height: '100%', marginTop: '1rem' }}
            className="outer-page"
        >
            <Autocomplete
                value={autoCompleteValue}
                options={users}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        #{users.findIndex((user) => user === option) + 1} -{' '}
                        {option}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="Users" />
                )}
                label="Contributors"
                getOptionDisabled={(option) =>
                    autoCompleteValue.includes(option) ||
                    autoCompleteValue.length >= 10
                }
                onChange={(_, value) => setAutoCompleteValue(value)}
                autoHighlight
                disableCloseOnSelect={autoCompleteValue.length < 9}
                multiple
            />
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    borderRadius: '0.5rem',
                }}
            >
                <Line
                    options={options}
                    data={{
                        labels: labels,
                        datasets: datasets,
                    }}
                />
            </Box>
        </Stack>
    )
}
