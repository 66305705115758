export const makeAbbr = (username) => {
    // Set username abbreviation
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

    let initials = [...username.matchAll(rgx)] || []

    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()
    return initials
}