import React, { useEffect, useState } from 'react'
import { Box, Grid, Grow, IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import { getColorRedGreen } from '../../../utils/ColorUtils'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip as TooltipChart,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    TooltipChart,
    Legend
)

const BarChartGenreAverageRating = ({ profile }) => {
    const [genreOrder, setGenreOrder] = useState([])
    const [genreValues, setGenreValues] = useState([])

    useEffect(() => {
        const obj = {}
        profile.ratings.ratings.forEach((rating) => {
            rating.genres.forEach((genre) => {
                if (Object.keys(obj).includes(genre)) {
                    obj[genre].number += 1
                    obj[genre].total += rating.yourRating
                } else
                    obj[genre] = {
                        number: 1,
                        total: rating.yourRating,
                    }
            })
        })
        setGenreValues(obj)
        const res = {}
        Object.keys(obj).forEach((key) => {
            res[key] = obj[key].total / obj[key].number
        })

        const order = []
        for (let i = 0; i < Object.keys(res).length; i++) {
            const copy = Object.keys(res).filter((a) => !order.includes(a))
            let lowest = res[copy[0]]
            let lowestKey = copy[0]
            copy.forEach((key) => {
                if (lowest > res[key]) {
                    lowest = res[key]
                    lowestKey = key
                }
            })
            order.push(lowestKey)
        }
        setGenreOrder(order)
    }, [profile])

    if (profile.ratings === undefined || profile.ratings.ratings.length === 0)
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                    position: 'relative',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Genre Average Rating',
                            },
                            legend: { display: false },
                        },
                        scales: {
                            yAxes: {
                                max: 10,
                                min: 1,
                            },
                        },
                    }}
                    data={{
                        labels: genreOrder,
                        datasets: [
                            {
                                label: 'Average Rating',
                                data: genreOrder.map(
                                    (genre) =>
                                        Math.round(
                                            (genreValues[genre].total /
                                                genreValues[genre].number) *
                                            100
                                        ) / 100
                                ),
                                backgroundColor: genreOrder
                                    .map((_, index) =>
                                        getColorRedGreen(
                                            index / genreOrder.length
                                        )
                                    )
                                    .reverse(),
                            },
                        ],
                    }}
                />
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '0.1rem',
                        right: '0.1rem',
                    }}
                >
                    <Tooltip
                        TransitionComponent={Grow}
                        TransitionProps={{ timeout: 250 }}
                        title="Based on top 3 genres for each title"
                        arrow
                        placement="bottom"
                    >
                        <InfoIcon sx={{ color: 'white' }} />
                    </Tooltip>
                </IconButton>
            </Box>
        </Grid>
    )
}

export default BarChartGenreAverageRating
