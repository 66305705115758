import { useState } from 'react'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import {
    TextField,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Container,
    InputLabel,
    Snackbar,
    FormControl,
    Button,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoginIcon from '@mui/icons-material/Login'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LockResetIcon from '@mui/icons-material/LockReset'
import AppIcon from '../../resources/images/logo.png'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import { apiRequestPost } from '../../utils/api.js'

export const Login = ({ user }) => {
    const navigate = useNavigate()

    if (user.isLoggedIn) navigate('/')

    const [cookies, setCookie] = useCookies(['jwt'])

    const [form, setForm] = useState({
        email: '',
        password: '',
        error: '',
        showPassword: false,
    })

    // These methods will update the state properties.
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value }
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const [loading, setLoading] = React.useState(false)

    const [openSnackbar, setOpenSnackbar] = React.useState(false)

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return

        setOpenSnackbar(false)
    }

    const gotoSignup = () => {
        navigate('/signup')
    }

    // Handle signin
    async function onSubmit(e) {
        e.preventDefault()
        setLoading(true)
        updateForm({ error: '' })
        setOpenSnackbar(false)

        const signin = await apiRequestPost('/signin', form, cookies)

        if (signin.token) {
            // TODO: For same page
            // setCookie('jwt', signin.token, {
            //     path: '/',
            //     expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
            //     sameSite: 'none',
            //     secure: true
            // });
            setCookie('jwt', signin.token, {
                path: '/',
                expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
            })
            navigate('/')
        } else if (signin.error) {
            updateForm({ error: signin.error })
            setLoading(false)
            setOpenSnackbar(true)
        } else {
            updateForm({ error: 'An unknown error has occured' })
            setLoading(false)
            setOpenSnackbar(true)
        }
    }

    return (
        <Container className="outer-page">
            <Container
                maxWidth="xs"
                style={{
                    margin: 'auto',
                    backgroundColor: '#abd1b7',
                    padding: '2rem',
                    borderRadius: '1rem',
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <img className="App-logo" src={AppIcon} alt="AutoIMDb" />
                    <h2 style={{ color: 'white' }}>Sign into your account</h2>
                </div>
                <form
                    onSubmit={onSubmit}
                    className="mt-8 space-y-6"
                    autoComplete="off"
                    style={{ textAlign: 'center' }}
                >
                    <TextField
                        fullWidth={true}
                        required={true}
                        id="email"
                        label="E-mail"
                        type="email"
                        value={form.email}
                        onChange={(e) => updateForm({ email: e.target.value })}
                        style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                        }}
                    />
                    <FormControl
                        fullWidth={true}
                        variant="outlined"
                        style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            fullWidth={true}
                            required={true}
                            id="password"
                            label="Password"
                            type={form.showPassword ? 'text' : 'password'}
                            onChange={(e) =>
                                updateForm({ password: e.target.value })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) =>
                                            updateForm({
                                                showPassword:
                                                    !form.showPassword,
                                            })
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {form.showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <LoadingButton
                        fullWidth={true}
                        loading={loading}
                        startIcon={<LoginIcon />}
                        variant="outlined"
                        type="submit"
                        loadingIndicator="Signing In..."
                        style={{
                            marginTop: '0.5rem',
                        }}
                    >
                        Sign In
                    </LoadingButton>
                    <Button
                        fullWidth={true}
                        variant="outlined"
                        startIcon={<PersonAddIcon />}
                        style={{
                            marginTop: '0.5rem',
                        }}
                        onClick={gotoSignup}
                    >
                        Sign Up
                    </Button>
                    <Button
                        fullWidth={true}
                        variant="outlined"
                        startIcon={<LockResetIcon />}
                        style={{
                            marginTop: '0.5rem',
                        }}
                    >
                        Forgot Password
                    </Button>
                </form>
            </Container>
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {form.error}
                </Alert>
            </Snackbar>
        </Container>
    )
}
