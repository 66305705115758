import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, Grow, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip as TooltipChart,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    TooltipChart,
    Legend
)

const BarChartRatingsByGenre = ({ profile }) => {
    const [genreOrder, setGenreOrder] = useState([])
    useEffect(() => {
        if (
            profile.ratings === undefined ||
            profile.ratings.byGenre === undefined ||
            Object.keys(profile.ratings.byGenre).length === 0
        )
            return

        const order = []
        for (let i = 0; i < Object.keys(profile.ratings.byGenre).length; i++) {
            const copy = Object.keys(profile.ratings.byGenre).filter(
                (a) => !order.includes(a)
            )
            let lowest = profile.ratings.byGenre[copy[0]]
            let lowestKey = copy[0]
            copy.forEach((key) => {
                if (lowest > profile.ratings.byGenre[key]) {
                    lowest = profile.ratings.byGenre[key]
                    lowestKey = key
                }
            })
            order.push(lowestKey)
        }

        setGenreOrder(order)
    }, [profile])

    if (
        profile.ratings === undefined ||
        profile.ratings.byGenre === undefined ||
        Object.keys(profile.ratings.byGenre).length === 0
    )
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                    position: 'relative',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: 'Ratings by Genre',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')} - ${Math.round(
                                            1000 *
                                            (parseInt(yDatapoint.raw) /
                                                Math.max(
                                                    profile.titles_rated,
                                                    1
                                                )),
                                            0
                                        ) / 10
                                            }%`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: genreOrder,
                        datasets: [
                            {
                                label: 'Number of Ratings',
                                data: genreOrder.map(
                                    (genre) => profile.ratings.byGenre[genre]
                                ),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                        ],
                    }}
                />
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '0.1rem',
                        right: '0.1rem',
                    }}
                >
                    <Tooltip
                        TransitionComponent={Grow}
                        TransitionProps={{ timeout: 250 }}
                        title="Based on top 3 genres for each title"
                        arrow
                        placement="bottom"
                    >
                        <InfoIcon sx={{ color: 'white' }} />
                    </Tooltip>
                </IconButton>
            </Box>
        </Grid>
    )
}

export default BarChartRatingsByGenre
