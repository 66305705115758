import React, { useEffect, useState } from 'react'
import {
    Grid,
    CircularProgress,
    SpeedDial,
    SpeedDialAction,
} from '@mui/material'
import { apiRequestGet } from '../utils/api'
import ContributionCard from '../components/ContributionLog/ContributionCard'
import SettingsIcon from '@mui/icons-material/Settings'
import DateRangeIcon from '@mui/icons-material/DateRange'
import EventIcon from '@mui/icons-material/Event'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import ViewModuleIcon from '@mui/icons-material/ViewModule'
import { indexedMonthToText } from '../utils/time'

const actions = [
    { icon: <ViewModuleIcon />, name: 'Log' },
    { icon: <EventIcon />, name: 'Day' },
    { icon: <DateRangeIcon />, name: 'Week' },
    { icon: <CalendarMonthIcon />, name: 'Month' },
    { icon: <CalendarViewMonthIcon />, name: 'Year' },
]

function getWeek(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7)
}

const ContributionLog = () => {
    document.title = 'AutoIMDb | Contribution Log'

    const [data, setData] = useState([])
    useEffect(() => {
        async function fetchData() {
            const req = await apiRequestGet('/upload-statistics')
            if (!req.error && req) {
                const logs = req.logs
                logs.forEach(
                    (item) => (item.timestamp = new Date(item.timestamp))
                )
                logs.sort((a, b) => b.timestamp - a.timestamp)
                setData(logs)
            }
        }
        fetchData()
    }, [])

    // * By log, by day, by week, by month, by year
    const [display, setDisplay] = useState('Day')

    const [processedData, setProcessedData] = useState([])
    useEffect(() => {
        if (display === 'Log') setProcessedData(data)
        else if (display === 'Day') {
            const days = {}
            data.forEach((item) => {
                const date = new Date(item.timestamp)
                const timestamp = `${`${date.getFullYear()}`.padStart(
                    2,
                    '0'
                )}-${`${date.getMonth() + 1}`.padStart(
                    2,
                    '0'
                )}-${`${date.getDate()}`.padStart(2, '0')}`
                if (days[timestamp]) {
                    days[timestamp].uploadedEpisodes += item.uploadedEpisodes
                    days[timestamp].processedEpisodes += item.processedEpisodes
                    days[timestamp].runtimeMs += item.runtimeMs
                } else
                    days[timestamp] = {
                        timestamp,
                        uploadedEpisodes: item.uploadedEpisodes,
                        processedEpisodes: item.processedEpisodes,
                        runtimeMs: item.runtimeMs,
                    }
            })

            for (const key in days)
                days[key].contributionsPerS =
                    (days[key].uploadedEpisodes * 3) /
                    (days[key].runtimeMs / 1000)

            setProcessedData(Object.values(days))
        } else if (display === 'Week') {
            const weeks = {}
            data.forEach((item) => {
                const date = new Date(item.timestamp)
                const timestamp = `${date.getFullYear()} - Week ${getWeek(
                    date
                )}`

                if (weeks[timestamp]) {
                    weeks[timestamp].uploadedEpisodes += item.uploadedEpisodes
                    weeks[timestamp].processedEpisodes += item.processedEpisodes
                    weeks[timestamp].runtimeMs += item.runtimeMs
                } else
                    weeks[timestamp] = {
                        timestamp,
                        uploadedEpisodes: item.uploadedEpisodes,
                        processedEpisodes: item.processedEpisodes,
                        runtimeMs: item.runtimeMs,
                    }
            })

            for (const key in weeks)
                weeks[key].contributionsPerS =
                    (weeks[key].uploadedEpisodes * 3) /
                    (weeks[key].runtimeMs / 1000)

            setProcessedData(Object.values(weeks))
        } else if (display === 'Month') {
            const months = {}
            data.forEach((item) => {
                const date = new Date(item.timestamp)
                const timestamp = `${indexedMonthToText(
                    date.getMonth()
                )} ${date.getFullYear()}`

                if (months[timestamp]) {
                    months[timestamp].uploadedEpisodes += item.uploadedEpisodes
                    months[timestamp].processedEpisodes +=
                        item.processedEpisodes
                    months[timestamp].runtimeMs += item.runtimeMs
                } else
                    months[timestamp] = {
                        timestamp,
                        uploadedEpisodes: item.uploadedEpisodes,
                        processedEpisodes: item.processedEpisodes,
                        runtimeMs: item.runtimeMs,
                    }
            })

            for (const key in months)
                months[key].contributionsPerS =
                    (months[key].uploadedEpisodes * 3) /
                    (months[key].runtimeMs / 1000)

            setProcessedData(Object.values(months))
        } else {
            const years = {}
            data.forEach((item) => {
                const date = new Date(item.timestamp)
                const timestamp = date.getFullYear().toString()

                if (years[timestamp]) {
                    years[timestamp].uploadedEpisodes += item.uploadedEpisodes
                    years[timestamp].processedEpisodes += item.processedEpisodes
                    years[timestamp].runtimeMs += item.runtimeMs
                } else
                    years[timestamp] = {
                        timestamp,
                        uploadedEpisodes: item.uploadedEpisodes,
                        processedEpisodes: item.processedEpisodes,
                        runtimeMs: item.runtimeMs,
                    }
            })

            for (const key in years)
                years[key].contributionsPerS =
                    (years[key].uploadedEpisodes * 3) /
                    (years[key].runtimeMs / 1000)

            setProcessedData(Object.values(years))
        }
    }, [data, display])

    return processedData.length === 0 ? (
        <CircularProgress
            color="success"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            size="8rem"
        />
    ) : (
        <div style={{ position: 'relative', width: '100%' }}>
            <Grid container spacing={1} className="outer-page">
                {processedData.map((item) => (
                    <ContributionCard data={item} key={item.timestamp} />
                ))}
            </Grid>
            <SpeedDial
                ariaLabel="SpeedDial viewing"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SettingsIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => setDisplay(action.name)}
                        tooltipOpen
                        className={display === action.name ? 'active' : ''}
                    />
                ))}
            </SpeedDial>
        </div>
    )
}
export default ContributionLog
