import React, { useState } from 'react'
import { Avatar, Grid, Tooltip } from '@mui/material'

function numberToImageId(id) {
    if (id === undefined) return '000'
    else return `${'0'.repeat(3 - String(id).length)}${id}`
}

const AchievementCard = ({
    image_id,
    badge_name,
    current_milestone,
    next_milestone,
    total_achieved_subbadges,
    total_subbadges,
    is_next_badge,
}) => {
    const [hovering, setHovering] = useState(false)
    const images = require.context('../../../../public/images/Badges', true)
    if (badge_name.includes('Bio') || badge_name.includes('Plot'))
        badge_name = `Top ${badge_name} Writer`

    // TODO: Add hover effect showing missing episodes for lists
    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
            <div className="achievement-card">
                {
                    // * Current Badge
                    <Tooltip
                        title={
                            total_achieved_subbadges > 0
                                ? 'Current Badge'
                                : 'No Current Badge'
                        }
                        arrow
                        TransitionProps={{ timeout: 250 }}
                    >
                        <Avatar
                            src={
                                total_achieved_subbadges > 0
                                    ? images(
                                        `./image_part_${numberToImageId(
                                            image_id
                                        )}.png`
                                    )
                                    : images(`./image_part_000.png`)
                            }
                            alt="Badge"
                            className="badge-image"
                        />
                    </Tooltip>
                }
                {
                    // * Next badge if at least one milestone has previously been reached
                    is_next_badge && (
                        <Tooltip
                            title="Badge upon hitting milestone"
                            arrow
                            TransitionProps={{ timeout: 250 }}
                        >
                            <Avatar
                                src={images(
                                    `./image_part_${numberToImageId(
                                        is_next_badge &&
                                            total_achieved_subbadges > 0
                                            ? image_id + 1
                                            : image_id
                                    )}.png`
                                )}
                                alt="Badge"
                                className="badge-image next-badge"
                            />
                        </Tooltip>
                    )
                }
                <div className="badge-name">
                    {badge_name} ({total_achieved_subbadges} / {total_subbadges}
                    )
                </div>
                <div
                    className="badge-bar-total"
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <div
                        className="badge-bar-acomplished"
                        style={{
                            width: `${Math.round(
                                (current_milestone / next_milestone) * 10000
                            ) / 100
                                }%`,
                        }}
                    />
                    <span className="position-text-transition-centered">
                        {hovering
                            ? `${Math.round(
                                (current_milestone / next_milestone) *
                                10000
                            ) / 100
                            }%`
                            : `${current_milestone.toLocaleString(
                                'en-US'
                            )} / ${next_milestone.toLocaleString('en-US')}`}</span>
                </div>
            </div>
        </Grid>
    )
}

export default AchievementCard
