import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import { FaStar } from 'react-icons/fa'
import LazyLoad from 'react-lazyload'

export const RatingComparison = ({ headline, ratings, comparison }) => {
    // * Holds the state of the expanded button
    const containerRef = useRef(null)
    const [expanded, setExpanded] = useState(false)
    const [numItems, setNumItems] = useState(5) // Number of items initially displayed

    useEffect(() => {
        const handleResize = () => {
            try {
                const containerWidth = containerRef.current.offsetWidth
                const itemWidth = 177.5 // Adjust this value according to your item width
                const newNumItems = Math.floor(containerWidth / itemWidth)
                setNumItems(newNumItems)
            } catch (_) {}
        }

        window.addEventListener('resize', handleResize)
        handleResize() // Initial calculation on mount

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <h4>
                {headline}{' '}
                {numItems >= ratings.length ? (
                    ''
                ) : (
                    <>
                        {'('}
                        <u onClick={handleExpand} className="pointer">
                            {expanded ? 'Collapse' : 'Expand'}
                        </u>
                        {')'}
                    </>
                )}
            </h4>
            <Grid
                container
                spacing={2.5}
                rowSpacing={expanded ? 2.5 : 0.5}
                justifyContent="center"
                alignItems="center"
                ref={containerRef}
                className="comparison-grid"
            >
                {
                    // * Display the first 5 items
                    ratings.slice(0, numItems).map((rating) => {
                        return (
                            <Grid item key={rating.id}>
                                <a
                                    href={`https://imdb.com/title/${rating.id}/`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <LazyLoad>
                                        <img
                                            src={`https://m.media-amazon.com/images/M/${rating.image_link}`}
                                            title={rating.title}
                                            alt={rating.title}
                                            className="comparison-image"
                                        />
                                    </LazyLoad>
                                </a>
                                <div className="comparison-title">
                                    <a
                                        href={`https://imdb.com/title/${rating.id}/`}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={rating.title}
                                    >
                                        {rating.title}
                                    </a>
                                </div>
                                <div className="comparison-ratings">
                                    <span
                                        className="left"
                                        title={`${comparison.profile1.username}'s Rating`}
                                    >
                                        <FaStar style={{ color: 'blue' }} />
                                        {rating.profile1}
                                    </span>
                                    <span title="Average Rating">
                                        {rating.average}
                                    </span>
                                    <span
                                        className="right"
                                        title={`${comparison.profile2.username}'s Rating`}
                                    >
                                        {rating.profile2}
                                        <FaStar style={{ color: '#63d67a' }} />
                                    </span>
                                </div>
                            </Grid>
                        )
                    })
                }
                {
                    // * Display the remaining items
                    expanded &&
                        ratings
                            .slice(numItems, ratings.length)
                            .map((rating) => {
                                return (
                                    <Grid item key={rating.id}>
                                        <a
                                            href={`https://imdb.com/title/${rating.id}/`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <LazyLoad>
                                                <img
                                                    src={`https://m.media-amazon.com/images/M/${rating.image_link}`}
                                                    title={rating.title}
                                                    alt={rating.title}
                                                    className="comparison-image"
                                                />
                                            </LazyLoad>
                                        </a>
                                        <div className="comparison-title">
                                            <a
                                                href={`https://imdb.com/title/${rating.id}/`}
                                                target="_blank"
                                                rel="noreferrer"
                                                title={rating.title}
                                            >
                                                {rating.title}
                                            </a>
                                        </div>
                                        <div className="comparison-ratings">
                                            <span
                                                style={{ float: 'left' }}
                                                title={`${comparison.profile1.username}'s Rating`}
                                            >
                                                <FaStar
                                                    style={{ color: 'orange' }}
                                                />
                                                {rating.profile1}
                                            </span>
                                            <span
                                                style={{ float: 'none' }}
                                                title="Average Rating"
                                            >
                                                {rating.average}
                                            </span>
                                            <span
                                                style={{ float: 'right' }}
                                                title={`${comparison.profile2.username}'s Rating`}
                                            >
                                                {rating.profile2}
                                                <FaStar
                                                    style={{ color: 'blue' }}
                                                />
                                            </span>
                                        </div>
                                    </Grid>
                                )
                            })
                }
                {
                    // * Display empty items to fill the grid
                    <Grid
                        item
                        xs={
                            (12 / numItems) *
                            (numItems - (ratings.length % numItems))
                        }
                    />
                }
            </Grid>
        </>
    )
}
