import React, { useState } from 'react'
import { Box, Grid, Slider } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChartRatingsTypeByYear = ({ profile }) => {
    const ratings = profile.ratings.ratings

    // * Array of types holding all possible types, followed by object years containing amount of ratings per year, KEY: year (String)
    const types = []
    const years = {}
    ratings.forEach((rating) => {
        if (!types.includes(rating.type)) types.push(rating.type)
    })
    ratings.forEach((rating) => {
        const d = new Date(rating.rated_at)
        if (!Object.keys(years).includes(d.getFullYear().toString())) {
            years[d.getFullYear().toString()] = {}
            types.forEach(
                (type) => (years[d.getFullYear().toString()][type] = 0)
            )
        }

        if (years[d.getFullYear().toString()][rating.type] !== undefined)
            years[d.getFullYear().toString()][rating.type]++
        else years[d.getFullYear().toString()][rating.type] = 1
    })

    // * Handle slider (shown years)
    const actualYears = Object.keys(years)
    const [viewYears, setViewYears] = useState([
        parseInt(Object.keys(years).at(0)),
        parseInt(Object.keys(years).at(-1)),
    ])
    const handleChangeViewYears = (event, newValue) => {
        setViewYears(newValue)
    }
    actualYears.forEach((year) => {
        if (parseInt(year) < viewYears[0] || parseInt(year) > viewYears[1])
            delete years[year.toString()]
    })

    // * Convert data for years to datasets
    const numRatings = {}
    types.forEach((item) => (numRatings[item] = 0))
    ratings.forEach((rating) => {
        numRatings[rating.type]++
    })
    const order = Object.values(numRatings).sort((a, b) => b - a)
    const newOrderTypes = []
    order.forEach((val) => {
        for (let j = 0; j < types.length; j++) {
            const type = types[j]
            if (val === numRatings[type]) newOrderTypes.push(type)
        }
    })

    const datasets = []
    const backgroundColors = [
        '#16CC62',
        '#196EE6',
        '#E6B219',
        '#E6196E',
        '#19C3E6',
        '#116149',
        '#E65D19',
        '#8A27A5',
    ]
    newOrderTypes.forEach((type, index) => {
        datasets.push({
            label: !type.endsWith('s')
                ? `${type.charAt(0).toUpperCase()}${type.substring(1)}s`
                : `${type.charAt(0).toUpperCase()}${type.substring(1)}`,
            data: [],
            backgroundColor: backgroundColors[index],
        })
        Object.keys(years).forEach((year) => {
            datasets[index].data.push(years[year][type])
        })
    })

    const marks = []
    actualYears.forEach((year) => {
        marks.push({
            value: parseInt(year),
        })
    })

    return (
        <Grid
            item
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
            style={{ overflow: 'hidden' }}
        >
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Type of Ratings Made by Year',
                            },
                        },
                    }}
                    data={{
                        labels: Object.keys(years),
                        datasets,
                    }}
                />
                <Slider
                    style={{
                        marginRight: '1rem',
                        marginLeft: '1rem',
                        marginTop: '.75rem',
                        width: 'calc(100% - 2rem)',
                    }}
                    getAriaLabel={() => 'Year range'}
                    value={viewYears}
                    onChange={handleChangeViewYears}
                    valueLabelDisplay="auto"
                    min={
                        actualYears.length > 0
                            ? parseInt(actualYears.at(0))
                            : new Date().getFullYear()
                    }
                    max={
                        actualYears.length > 0
                            ? parseInt(actualYears.at(-1))
                            : new Date().getFullYear()
                    }
                    marks={marks}
                    step={null}
                />
            </Box>
        </Grid>
    )
}
export default BarChartRatingsTypeByYear
