const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

export const indexToMonth = (index) => {
    return months[index];
}

export const monthToIndex = (month) => {
    return months.indexOf(month)
}

export const monthYearToValue = (text) => {
    const [month, year] = text.split(' ')

    return parseInt(year) * 12 + monthToIndex(month)
}

export const monthYearSorting = (a, b) => {
    const [aMonth, aYear] = a._id.split(' ')
    const [bMonth, bYear] = b._id.split(' ')

    const aValue = parseInt(aYear) * 12 + monthToIndex(aMonth)
    const bValue = parseInt(bYear) * 12 + monthToIndex(bMonth)

    if (aValue < bValue) return -1
    if (aValue > bValue) return 1
    return 0
}