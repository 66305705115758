import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { indexToMonth } from '../../utils/dates.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const RatingsByMonth = ({ ratings1, ratings2, profile1, profile2 }) => {
    if (ratings1 === undefined && ratings2 === undefined)
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    const months1 = {}
    const months2 = {}
    for (let i = 0; i < 12; i++) {
        months1[i.toString()] = 0
        months2[i.toString()] = 0
    }

    ratings1.forEach((rating) => {
        const date = new Date(rating.rated_at).getMonth()
        months1[date.toString()]++
    })
    ratings2.forEach((rating) => {
        const date = new Date(rating.rated_at).getMonth()
        months2[date.toString()]++
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Ratings Made by Month',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')}`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: Array.from(
                            { length: 12 },
                            (_, index) => index
                        ).map((index) => indexToMonth(index)),
                        datasets: [
                            {
                                label: `${profile1.username}`,
                                data: Object.values(months1),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: `${profile2.username}`,
                                data: Object.values(months2),
                                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default RatingsByMonth
