import React, { useEffect, useState } from 'react'
import { BarChartCard } from '../components/Cards/BarChartCard'
import { LineChartCard } from '../components/Cards/LineChartCard'
import { Grid } from '@mui/material'
import { StatCard } from '../components/Cards/StatCard'
import { StatCardCounter } from '../components/Cards/StatCardCounter'
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice'
import ReorderIcon from '@mui/icons-material/Reorder'
import ImageIcon from '@mui/icons-material/Image'
import GroupIcon from '@mui/icons-material/Group'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import AddchartIcon from '@mui/icons-material/Addchart'
import TerrainIcon from '@mui/icons-material/Terrain'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { socket } from '../utils/api.js'

export const Dashboard = ({ data, setData }) => {
    document.title = 'AutoIMDb | Dashboard'

    const [currentPodcasts, setCurrentPodcasts] = useState(0)
    const [currentEpisodes, setCurrentEpisodes] = useState(0)
    const [currentPending, setCurrentPending] = useState(0)
    const [currentAccepted, setCurrentAccepted] = useState(0)
    const [currentImages, setCurrentImages] = useState(0)
    const [currentRemainingPodcasts, setCurrentRemainingPodcasts] = useState(0)
    const [currentRemainingEpisodes, setCurrentRemainingEpisodes] = useState(0)
    const [currentRemainingImages, setCurrentRemainingImages] = useState(0)
    useEffect(() => {
        function update(value) {
            if (
                currentPodcasts !== 0 &&
                currentEpisodes !== 0 &&
                currentPending !== 0 &&
                currentAccepted !== 0 &&
                currentRemainingPodcasts !== 0 &&
                currentRemainingEpisodes !== 0 &&
                currentRemainingImages !== 0 &&
                currentImages !== 0
            )
                setData((previous) => ({
                    ...previous,
                    pending: currentPending,
                    contributions: currentAccepted,
                    podcasts: currentPodcasts,
                    episodes: currentEpisodes,
                    images: currentImages,
                    remaining_podcasts: currentRemainingPodcasts,
                    remaining_episodes: currentRemainingEpisodes,
                    remaining_images: currentRemainingImages,
                    pending_episodes: value.pendingEpisodes
                        ? value.pendingEpisodes
                        : previous.pending_episodes,
                    pending_podcasts: value.pendingPodcasts
                        ? value.pendingPodcasts
                        : previous.pending_podcasts,
                    pending_images: value.pendingImages
                        ? value.pendingImages
                        : previous.pending_images,
                    pending_playtimes: value.pendingPlaytimes
                        ? value.pendingPlaytimes
                        : previous.pending_playtimes,
                }))
            setCurrentPodcasts(value.podcasts)
            setCurrentEpisodes(value.episodes)
            setCurrentPending(value.pending)
            setCurrentAccepted(value.accepted)
            setCurrentImages(value.images)
            setCurrentRemainingPodcasts(value.remainingPodcasts)
            setCurrentRemainingEpisodes(value.remainingEpisodes)
            setCurrentRemainingImages(value.remainingImages)
        }

        socket.on('update', update)

        return () => {
            socket.off('update', update)
        }
    }, [
        currentAccepted,
        currentEpisodes,
        currentImages,
        currentPending,
        currentPodcasts,
        currentRemainingEpisodes,
        currentRemainingImages,
        currentRemainingPodcasts,
        data,
        setData,
    ])

    function formatNumber(value) {
        try {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } catch (e) {
            console.log(e)
        }
    }

    function perc2color(perc) {
        var r,
            g,
            b = 0
        if (perc < 50) {
            r = 255
            g = Math.round(5.1 * perc)
        } else {
            g = 255
            r = Math.round(510 - 5.1 * perc)
        }
        var h = r * 0x10000 + g * 0x100 + b * 0x1
        return '#' + ('000000' + h.toString(16)).slice(-6)
    }

    function secToFormat(seconds) {
        if (seconds === 'undefined' || seconds === undefined) return 'N/A'

        let hours = 0
        let minutes = 0
        while (seconds >= 3600) {
            hours++
            seconds -= 3600
        }
        while (seconds >= 60) {
            minutes++
            seconds -= 60
        }

        if (hours > 0 && minutes > 0)
            return hours + ' hours and ' + minutes + ' min'
        else if (hours > 0) return hours + ' hours'
        else if (minutes > 0 && seconds > 0)
            return minutes + ' min ' + Math.floor(seconds) + ' sec'
        else if (minutes > 0) return minutes + ' min'
        else if (seconds > 0) return Math.floor(seconds) + ' sec'
        else return 'DONE'
    }

    return (
        <div className="w-full inline-block box-border" style={{ minHeight: 'calc(100vh - 64px)' }}>
            <div className="w-full h-full bg-repeat-y" style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                color: '#8d9399',
                padding: '10px 0px',
                overflowWrap: 'anywhere',
            }}>
                <Grid container columns={5} spacing={2} className="outer-page p-4">
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Podcasts"
                            fromValue={data.podcasts}
                            toValue={currentPodcasts}
                            icon={<SettingsVoiceIcon />}
                            color="purple"
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Episodes"
                            fromValue={data.episodes}
                            toValue={currentEpisodes}
                            icon={<ReorderIcon />}
                            color="orchid"
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Images"
                            fromValue={data.images}
                            toValue={currentImages}
                            icon={<ImageIcon />}
                            color="specialgreen"
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCard
                            type="Users"
                            value={data.users}
                            icon={<GroupIcon />}
                            color="orange"
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCard
                            type="Declined Items"
                            value={formatNumber(data.declined)}
                            icon={<DoNotDisturbAltIcon />}
                            color="red"
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Remaining Podcasts"
                            fromValue={data.remaining_podcasts}
                            toValue={currentRemainingPodcasts}
                            icon={<TrendingUpIcon />}
                            color="dark"
                            extra_field={(
                                ((data.podcasts - data.remaining_podcasts) /
                                    data.podcasts) *
                                100
                            ).toFixed(2)}
                            extra_field_color={perc2color(
                                (
                                    ((data.podcasts - data.remaining_podcasts) /
                                        data.podcasts) *
                                    100
                                ).toFixed(2)
                            )}
                            info={'ETA: ' + secToFormat(data.remaining_podcasts * 2)}
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Remaining Episodes"
                            fromValue={data.remaining_episodes}
                            toValue={currentRemainingEpisodes}
                            icon={<AddchartIcon />}
                            color="darkblue"
                            extra_field={(
                                ((data.episodes - data.remaining_episodes) /
                                    data.episodes) *
                                100
                            ).toFixed(2)}
                            extra_field_color={perc2color(
                                (
                                    ((data.episodes - data.remaining_episodes) /
                                        data.episodes) *
                                    100
                                ).toFixed(2)
                            )}
                            info={'ETA: ' + secToFormat(data.remaining_episodes / 2.53)}
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Remaining Images"
                            fromValue={data.remaining_images}
                            toValue={currentRemainingImages}
                            icon={<TerrainIcon />}
                            color="blue"
                            extra_field={(
                                ((data.images - data.remaining_images) / data.images) *
                                100
                            ).toFixed(2)}
                            extra_field_color={perc2color(
                                (
                                    ((data.images - data.remaining_images) /
                                        data.images) *
                                    100
                                ).toFixed(2)
                            )}
                            info={'ETA: ' + secToFormat(data.remaining_images)}
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Pending Contributions"
                            fromValue={data.pending}
                            toValue={currentPending}
                            icon={<PendingActionsIcon />}
                            color="yellow"
                            info={
                                formatNumber(data.pending_episodes) +
                                ' episodes ' +
                                formatNumber(data.pending_podcasts) +
                                ' podcasts ' +
                                formatNumber(data.pending_images) +
                                ' images ' +
                                formatNumber(data.pending_playtimes) +
                                ' playtimes'
                            }
                        />
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1} lg={1} xl={1}>
                        <StatCardCounter
                            type="Monthly Contributions"
                            fromValue={data.contributions}
                            toValue={currentAccepted}
                            icon={<CalendarMonthIcon />}
                            color="green"
                        />
                    </Grid>
                    <Grid item xs={5} sm={5} md={2.5} lg={2.5} xl={2.5}>
                        <BarChartCard data={data.contributions_data} />
                    </Grid>
                    <Grid item xs={5} sm={5} md={2.5} lg={2.5} xl={2.5}>
                        <LineChartCard data={data.unprocessed_data} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
