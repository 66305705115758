import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

export const Logout = () => {
    const [, , removeCookie] = useCookies(['jwt'])
    const navigate = useNavigate()

    const logOut = () => {
        removeCookie('jwt')
        navigate('/')
    }
    logOut()

    return <div>Successfully logged out!</div>
}
