import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const MainstreamDivision = ({ profile1, profile2, ratings1, ratings2 }) => {
    if (ratings1 === undefined && ratings2 === undefined)
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    const division1 = {}
    const division2 = {}
    const years = new Set()
    ratings1.forEach((rating) => {
        if (rating.type !== 'episode') {
            if (!Object.keys(division1).includes(String(rating.releaseYear)))
                division1[String(rating.releaseYear)] = {
                    other: 0,
                    mainstream: 0,
                }

            years.add(rating.releaseYear)

            if (rating.votes < 50000)
                division1[String(rating.releaseYear)].other++
            else division1[String(rating.releaseYear)].mainstream++
        }
    })
    ratings2.forEach((rating) => {
        if (rating.type !== 'episode') {
            if (!Object.keys(division2).includes(String(rating.releaseYear)))
                division2[String(rating.releaseYear)] = {
                    other: 0,
                    mainstream: 0,
                }

            years.add(rating.releaseYear)

            if (rating.votes < 50000)
                division2[String(rating.releaseYear)].other++
            else division2[String(rating.releaseYear)].mainstream++
        }
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Mainstream Division by Year',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')}`
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                            },
                        },
                    }}
                    data={{
                        labels: [...years].sort((a, b) => a - b),
                        datasets: [
                            {
                                label: `${profile1.username} (M)`,
                                data: [...years]
                                    .sort((a, b) => a - b)
                                    .map((year) => {
                                        if (
                                            Object.keys(division1).includes(
                                                String(year)
                                            )
                                        )
                                            return division1[String(year)]
                                                .mainstream
                                        else return 0
                                    }),
                                backgroundColor: 'lime',
                                stack: 'Stack 0',
                            },
                            {
                                label: `${profile1.username} (O)`,
                                data: [...years]
                                    .sort((a, b) => a - b)
                                    .map((year) => {
                                        if (
                                            Object.keys(division1).includes(
                                                String(year)
                                            )
                                        )
                                            return division1[String(year)].other
                                        else return 0
                                    }),
                                backgroundColor: 'red',
                                stack: 'Stack 0',
                            },
                            {
                                label: `${profile2.username} (M)`,
                                data: [...years]
                                    .sort((a, b) => a - b)
                                    .map((year) => {
                                        if (
                                            Object.keys(division2).includes(
                                                String(year)
                                            )
                                        )
                                            return division2[String(year)]
                                                .mainstream
                                        else return 0
                                    }),
                                backgroundColor: 'blue',
                                stack: 'Stack 1',
                            },
                            {
                                label: `${profile2.username} (O)`,
                                data: [...years]
                                    .sort((a, b) => a - b)
                                    .map((year) => {
                                        if (
                                            Object.keys(division2).includes(
                                                String(year)
                                            )
                                        )
                                            return division2[String(year)].other
                                        else return 0
                                    }),
                                backgroundColor: 'orange',
                                stack: 'Stack 1',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default MainstreamDivision
