import React from 'react'
import { Box, Grid } from '@mui/material'
import { getColorRedGreen } from '../../../utils/ColorUtils'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChartRatingsByDistribution = ({ profile }) => {
    if (
        profile.ratingsByScore === undefined ||
        profile.ratingsByScore.length === 0
    )
        return (
            <Grid
                item
                xs={2}
                sm={2}
                md={1}
                lg={1}
                xl={1}
            >
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: 'Ratings by Distributions',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')} - ${Math.round(
                                            1000 *
                                            (parseInt(yDatapoint.raw) /
                                                Math.max(
                                                    profile.titles_rated,
                                                    1
                                                )),
                                            0
                                        ) / 10
                                            }%`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: Object.keys(profile.ratingsByScore).map(
                            (item) => `Rating: ${item}`
                        ),
                        datasets: [
                            {
                                label: 'Ratings',
                                data: Object.values(profile.ratingsByScore),
                                backgroundColor: Object.keys(
                                    profile.ratingsByScore
                                )
                                    .map((_, index) =>
                                        getColorRedGreen(
                                            index /
                                            Object.keys(
                                                profile.ratingsByScore
                                            ).length
                                        )
                                    )
                                    .reverse(),
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default BarChartRatingsByDistribution
