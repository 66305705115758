import { Typography, Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export const Information = () => {
    document.title = 'AutoIMDb | Information'

    return (
        <div
            style={{
                backgroundColor: 'white',
                margin: 0,
                padding: '1rem',
            }}
        >
            <Typography variant="h3" component="h3" sx={{ fontWeight: 'bold' }}>
                AutoIMDb
            </Typography>
            <Typography variant="p" component="p">
                AutoIMDb is a website that let's you see statistics for IMDb
                Contributors, verify podcasts that have not yet been added to
                IMDb and eventually suggest new podcasts or help find new places
                to gather data on series, podcasts and episodes that are yet to
                be added to IMDb.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                AutoIMDb relation to IMDb:
            </Typography>
            <Typography variant="p" component="p">
                AutoIMDb{' '}
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                    is not related to IMDb
                </span>{' '}
                in any way shape or form and this website is NOT run by IMDb or
                any of it's partners. And is simply run by an above average
                interested programmer. Most of the data presented is data from
                the AutoIMDb database, however some data may be gathered from
                public sources on IMDb.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                What is the goal of AutoIMDb?
            </Typography>
            <Typography variant="p" component="p">
                AutoIMDb aims to increase the contributions, as well as
                improving the quality of contributions to the well known website
                IMDb. The way AutoIMDb aims to achieve this goal is to create a
                community around contributing and using the website as a hotspot
                for quality assurence for currently new podcasts. This can
                currently be done by making an account and starting to verify
                podcasts, once a podcast gets a certain amount of "approve"
                votes it will be uploaded to IMDb.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                Why the name AutoIMDb?
            </Typography>
            <Typography variant="p" component="p">
                The name AutoIMDb is a description of how the contribution part
                is ran, the website and contribution heavily relies on
                automation (thus the Auto) to upload the podcast along with all
                it's episodes.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                Why <span style={{ color: 'red' }}>YOU</span> are important to
                AutoIMDb
            </Typography>
            <Typography variant="p" component="p">
                AutoIMDb relies fully on you helping out to improve the quality
                of the data that is submitted to IMDb, to ensure the integrity
                of what is submitted and to help build on the the service even
                further. Improvement suggestions and new tips on new sources of
                information to gather information on series/podcasts/episodes
                that is yet to be added to IMDb is desireable. Information about
                how to get in touch can be found bottom right corner.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                What can I gain from helping out?
            </Typography>
            <Typography variant="p" component="p">
                AutoIMDb is primarely run by Nomissimon10 (me), and I am a{' '}
                <span style={{ fontStyle: 'italic' }}>poor</span> student with
                not much over to incentivize with money prices. However
                recognition for the great work that is done is offered in the
                sense that there is a{' '}
                <Link to="/leaderboard" target="_blank">
                    leaderboard
                </Link>{' '}
                that get's updated once a minute. And it is also intended to
                post a monthly leaderboard over who were involved in the
                contributions and their total contribution
                <Tooltip title="help-count: The total amount of contributions that was made due to their/your help">
                    <span style={{ fontStyle: 'italic' }}> help-count</span>
                </Tooltip>
                .
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                How can AutoIMDb contribute the massive amount each month?
            </Typography>
            <Typography variant="p" component="p">
                The reason the massive amount of contributions each month is
                that we use automated software that allows us to upload
                podcasts, episodes and series very quickly. And due to a
                database of items to be contributed that has grown steadily (and
                still is) daily new items can be uploaded every single day the
                clock around. The maximum tested upload-rate is around{' '}
                <span style={{ fontWeight: 'bold' }}>15.6/s (1,347,840/day)</span>,
                this is often depending on the quality of the contributions.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                How fixing issues with contributions can be done quickly
            </Typography>
            <Typography variant="p" component="p">
                Fixing issues that is related to items contributed can be solved
                in masses when issues are found, the reason for this is that the
                database consists of a big amount of data on what has been
                contributed and the status of the item also after it has been
                contributed to IMDb. This means that once an issue that covers
                multiple items has been found a fix can be added for future
                items, but also for items that has been added in the past. This
                ensures a quick resolve of issues.
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                So what are you waiting for?
            </Typography>
            <Typography variant="p" component="p">
                So the last remaining question is: "What are you waiting for?".
                Make an account and get started with ensuring the quality we all
                wan't to see on our favorite rating website: IMDb.
            </Typography>
            <Typography variant="h3" component="h3" sx={{ fontWeight: 'bold' }}>
                Q&A
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                Does AutoIMDb work on all devices?
            </Typography>
            <Typography variant="p" component="p">
                Unfortunately AutoIMDb has been primarely made for computers and
                devices with a generally bigger screen, this means that the
                experience using the website might be lower on mobile devices or
                other devices with a small screen. This is something we aim to
                improve in the future. (Suggestions are welcomed)
            </Typography>
            <Typography variant="h6" component="h6" sx={{ fontWeight: 'bold' }}>
                Is the website protected against attacks?
            </Typography>
            <Typography variant="p" component="p">
                Passwords are encrypted before inserting into the database, this
                means that the password shouldn't be compromised EVEN if
                AutoIMDb is involved in a data-breach. However strong passwords
                are always encouraged as this improves the security, the
                password should also be different to other passwords you usually
                use. Using an automated password and then storing that password
                using a password storing service like Google passwords, NordPass
                or other similar ones is encouraged. AutoIMDb does not actively
                look at IP's other than when it comes to rate-limiting,
                rate-limiting is implemented to prevent people from making a ton
                of requests to the servers leading to an outage and/or a slower
                responsetime when using the website. (This also makes it so
                people can't get into your account with strategies like
                brute-force)
            </Typography>
        </div>
    )
}
