import React, { useEffect, useState } from 'react'
import '../CSS/ProfileStats.css'
import Viewer from '../components/ProfileStats/Main/Viewer'
import ProfileDialog from '../components/ProfileStats/Main/ProfileDialog'
import { apiRequestGet } from '../utils/api'
// import IndexDBConnection from '../utils/IndexDBConnection'

// import { Polls } from '../components/ProfileStats/Polls'
// import { Reviews } from '../components/ProfileStats/Reviews'

export const ProfileStats = () => {
    document.title = 'AutoIMDb | Profile Viewer'

    // const [indexDb, setIndexDb] = useState(undefined)
    // useEffect(() => {
    //     setIndexDb(new IndexDBConnection())
    // }, [])

    // * Holds all information about user
    const [profile, setProfile] = useState({})
    useEffect(() => {
        if (
            localStorage.getItem('currentUserData') !== null &&
            localStorage.getItem('currentUserData') !== {}
        )
            setProfile(JSON.parse(localStorage.getItem('currentUserData')))
    }, [])

    // useEffect(() => {
    //     if (indexDb === undefined) return
    //     const viewingUser = localStorage.getItem('viewingUser')
    //     if (viewingUser !== null) {
    //         const searchUser = indexDb.getUser(viewingUser)
    //         if (searchUser) setProfile(searchUser)
    //     }
    // }, [indexDb])

    // * The profile picker section
    const [open, setOpen] = useState(Object.keys(profile).length === 0)

    useEffect(() => {
        setOpen(Object.keys(profile).length === 0)
        if (JSON.stringify(profile).length * 4 < 5 * 1000 * 1000)
            localStorage.setItem('currentUserData', JSON.stringify(profile))
        // if (profile !== {}) indexDb.addOrUpdateUser(profile)
    }, [profile])

    // * Quicksearch Tab
    const [preProcessed, setPreProcessed] = useState([])
    const [currentAPITime, setCurrentAPITime] = useState(0)

    useEffect(() => {
        const fetchPreProcessed = async () => {
            const preProcessedAccounts = await apiRequestGet(
                '/pre-processed-accounts'
            )
            if (preProcessedAccounts.error === undefined) {
                setPreProcessed(preProcessedAccounts.users)
                setCurrentAPITime(preProcessedAccounts.currentTime)
            }
        }

        // if (indexDb !== undefined) fetchPreProcessed()
        fetchPreProcessed()
    }, [profile])

    const [suggestionsActive, setSuggestionsActive] = useState(true)

    // * Dialog Component
    return (
        <div className="w-full inline-block box-border" style={{ minHeight: 'calc(100vh - 64px)' }}>
            <div className="w-full h-full bg-repeat-y" style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                color: '#8d9399',
                padding: '10px 0px',
                overflowWrap: 'anywhere',
            }}>
                <Viewer profile={profile} open={open} setOpen={setOpen} />

                <ProfileDialog
                    profile={profile}
                    setProfile={setProfile}
                    open={open}
                    setOpen={setOpen}
                    suggestionsActive={suggestionsActive}
                    setSuggestionsActive={setSuggestionsActive}
                    preProcessed={preProcessed}
                    currentAPITime={currentAPITime}
                />
            </div>
        </div>
    )
}
