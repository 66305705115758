import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import {
    TextField,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Container,
    InputLabel,
    Snackbar,
    FormControl,
    Button,
    Checkbox,
    FormControlLabel,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoginIcon from '@mui/icons-material/Login'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import LockResetIcon from '@mui/icons-material/LockReset'
import AppIcon from '../../resources/images/logo.png'
import PolicyIcon from '@mui/icons-material/Policy'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import { apiRequestPost } from '../../utils/api.js'

export const Signup = ({ user }) => {
    // Check logged in or not
    const navigate = useNavigate()

    if (user.isLoggedIn) navigate('/')

    const [cookies, setCookie] = useCookies(['jwt'])

    // Schema for data
    const [form, setForm] = useState({
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        error: '',
        showPassword: false,
        policyAccepted: false,
    })

    // These methods will update the state properties.
    function updateForm(value) {
        return setForm((prev) => {
            return { ...prev, ...value }
        })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const [loading, setLoading] = React.useState(false)

    const [openSnackbar, setOpenSnackbar] = React.useState(false)

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return

        setOpenSnackbar(false)
    }

    const gotoSignin = () => {
        navigate('/signin')
    }

    const openPrivacyPolicy = () => {
        window.open('/privacy-policy', '_blank', 'noopener,noreferrer')
    }

    // Handle signup
    async function onSubmit(e) {
        e.preventDefault()
        setLoading(true)
        updateForm({ error: '' })
        setOpenSnackbar(false)

        const signup = await apiRequestPost('/signup', form, cookies)
        if (signup.token) {
            setCookie('jwt', signup.token, {
                path: '/',
                expires: new Date(
                    Date.now() +
                        process.env.JWT_COOKIE_EXPIRES_IN * 24 * 60 * 60 * 1000
                ),
            })
            navigate('/')
        } else if (signup.error) {
            updateForm({ error: signup.error })
            setLoading(false)
            setOpenSnackbar(true)
        } else {
            updateForm({ error: 'An unknown error has occured' })
            setLoading(false)
            setOpenSnackbar(true)
        }
    }

    return (
        <Container className="outer-page">
            <Container
                maxWidth="xs"
                style={{
                    margin: 'auto',
                    backgroundColor: '#abd1b7',
                    padding: '2rem',
                    borderRadius: '1rem',
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <img className="App-logo" src={AppIcon} alt="AutoIMDb" />
                    <h2 style={{ color: 'white' }}>Sign Up</h2>
                </div>
                <form
                    onSubmit={onSubmit}
                    className="mt-8 space-y-6"
                    autoComplete="off"
                    style={{ textAlign: 'center' }}
                >
                    <TextField
                        fullWidth={true}
                        required={true}
                        label="Username"
                        type="text"
                        value={form.username}
                        onChange={(e) =>
                            updateForm({ username: e.target.value })
                        }
                        style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                        }}
                    />
                    <TextField
                        fullWidth={true}
                        required={true}
                        label="E-mail"
                        type="email"
                        value={form.email}
                        onChange={(e) => updateForm({ email: e.target.value })}
                        style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                        }}
                    />
                    <FormControl
                        fullWidth={true}
                        variant="outlined"
                        required={true}
                        style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            value={form.password}
                            fullWidth={true}
                            type={form.showPassword ? 'text' : 'password'}
                            onChange={(e) =>
                                updateForm({ password: e.target.value })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) =>
                                            updateForm({
                                                showPassword:
                                                    !form.showPassword,
                                            })
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {form.showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <FormControl
                        fullWidth={true}
                        variant="outlined"
                        required={true}
                        style={{
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                        }}
                    >
                        <InputLabel htmlFor="passwordConfirm">
                            Confirm Password
                        </InputLabel>
                        <OutlinedInput
                            id="passwordConfirm"
                            value={form.passwordConfirm}
                            fullWidth={true}
                            type={form.showPassword ? 'text' : 'password'}
                            onChange={(e) =>
                                updateForm({ passwordConfirm: e.target.value })
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                            updateForm({
                                                showPassword:
                                                    !form.showPassword,
                                            })
                                        }
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {form.showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Confirm Password"
                        />
                    </FormControl>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={form.policyAccepted}
                                onClick={() =>
                                    updateForm({
                                        policyAccepted: !form.policyAccepted,
                                    })
                                }
                            />
                        }
                        label="I have read and agree to autoimdb.com's Privacy Policy"
                        required={true}
                    />

                    <LoadingButton
                        fullWidth={true}
                        loading={loading}
                        startIcon={<PersonAddIcon />}
                        variant="outlined"
                        type="submit"
                        loadingIndicator="Signing Up..."
                        style={{
                            marginTop: '0.5rem',
                        }}
                    >
                        Sign Up
                    </LoadingButton>
                    <Button
                        fullWidth={true}
                        variant="outlined"
                        startIcon={<PolicyIcon />}
                        style={{
                            marginTop: '0.5rem',
                        }}
                        onClick={openPrivacyPolicy}
                    >
                        Privacy Policy
                    </Button>
                    <Button
                        fullWidth={true}
                        variant="outlined"
                        startIcon={<LoginIcon />}
                        style={{
                            marginTop: '0.5rem',
                        }}
                        onClick={gotoSignin}
                    >
                        Sign In
                    </Button>
                    <Button
                        fullWidth={true}
                        variant="outlined"
                        startIcon={<LockResetIcon />}
                        style={{
                            marginTop: '0.5rem',
                        }}
                    >
                        Forgot Password
                    </Button>
                </form>
            </Container>
            <Snackbar
                open={openSnackbar}
                onClose={handleCloseSnackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {form.error}
                </Alert>
            </Snackbar>
        </Container>
    )
}
