import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChartRatingsMadeByYear = ({ profile }) => {
    if (
        profile.ratings === undefined ||
        profile.ratings.ratings === undefined ||
        profile.ratings.ratings.length === 0
    )
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    const years = {}
    profile.ratings.ratings.forEach((rating) => {
        const date = new Date(rating.rated_at).getFullYear()
        if (Object.keys(years).includes(date.toString()))
            years[date.toString()]++
        else years[date.toString()] = 1
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: 'Ratings Made by Year',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')} - ${Math.round(
                                            1000 *
                                            (parseInt(yDatapoint.raw) /
                                                Math.max(
                                                    profile.titles_rated,
                                                    1
                                                )),
                                            0
                                        ) / 10
                                            }%`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: Object.keys(years),
                        datasets: [
                            {
                                label: 'Ratings',
                                data: Object.values(years),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default BarChartRatingsMadeByYear
