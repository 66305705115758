import React from 'react';
import { Grid, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';

const ContributionsBarChart = ({ coverage_data }) => {
    let labels = [];
    let datasets = [];

    if (coverage_data !== undefined && Object.keys(coverage_data).includes('monthlySum')) {
        labels = coverage_data.monthlySum.labels;
        datasets = coverage_data.monthlySum.datasets;
    }

    return (
        <Grid
            item
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
        >
            <Box sx={{
                backgroundColor: 'rgb(33, 49, 60)',
                padding: '1rem',
                width: '100%',
                height: '100%',
                borderRadius: '0.5rem'
            }}>
                <Bar
                    options={{
                        responsive: true,
                        scales: {
                            A: {
                                id: 'A',
                                type: 'linear',
                                position: 'left',
                            },
                            B: {
                                type: 'linear',
                                position: 'right'
                            }
                        }
                    }}
                    data={{
                        labels,
                        datasets
                    }}
                />
            </Box>
        </Grid>
    );
}

export default ContributionsBarChart;