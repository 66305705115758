import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
    AppBar,
    Avatar,
    Box,
    IconButton,
    Toolbar,
    Tooltip,
} from '@mui/material'
import {
    deepOrange,
    deepPurple,
    green,
    grey,
    brown,
    amber,
} from '@mui/material/colors'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsIcon from '@mui/icons-material/Settings'
import { Link } from 'react-router-dom'

const NavbarRoot = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#18191c',
    boxShadow: theme.shadows[3],
}))

const colors = [green, deepOrange, deepPurple, grey, brown, amber]

const Navbar = ({ user, onSidebarOpen }) => {
    let colorIndex = 0
    let abbr = ''
    try {
        if (user.isLoggedIn) {
            abbr = user.abbr
            let value = 0
            for (let i = 0; i < abbr.length; i++) value += abbr.charCodeAt(i)
            colorIndex = value % colors.length
        }
    } catch (e) {}

    return (
        <>
            <NavbarRoot
                sx={{
                    left: {
                        lg: 280,
                    },
                    width: {
                        lg: 'calc(100% - 280px)',
                    },
                }}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: 64,
                        left: 0,
                        px: 2,
                    }}
                >
                    <IconButton
                        onClick={onSidebarOpen}
                        sx={{
                            display: {
                                xs: 'inline-flex',
                                lg: 'none',
                            },
                        }}
                    >
                        <MenuIcon fontSize="medium" />
                    </IconButton>

                    {/* Everything beyond this point will be located on the right */}
                    <Box sx={{ flexGrow: 1 }} />

                    {user.isLoggedIn && (
                        <>
                            <Link
                                to="/settings"
                                draggable={false}
                                style={{ textDecoration: 'none' }}
                            >
                                <Tooltip title="Settings">
                                    <IconButton sx={{ ml: 1 }}>
                                        <SettingsIcon fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            </Link>

                            <Tooltip title={user.username} placement="left">
                                <Avatar
                                    sx={{
                                        height: 40,
                                        width: 40,
                                        ml: 1,
                                        cursor: 'pointer',
                                        bgcolor: colors[colorIndex][500],
                                    }}
                                    style={{
                                        backgroundColor:
                                            colors[colorIndex][500],
                                    }}
                                >
                                    {abbr}
                                </Avatar>
                            </Tooltip>
                        </>
                    )}
                </Toolbar>
            </NavbarRoot>
        </>
    )
}

export default Navbar

Navbar.propTypes = {
    onSidebarOpen: PropTypes.func,
}
