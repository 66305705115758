export default class IndexDBConnection {
    constructor() {
        this.connection = window.indexedDB.open('UserDatabase', 1)

        this.connection.onerror = (error) => {
            console.error(error)
        }

        this.connection.onupgradeneeded = () => {
            const db = this.connection.result
            db.createObjectStore('users', { keyPath: 'id' })
        }

        this.connection.onsuccess = () => {
            console.log('Successfully connected to indexDB')
        }
    }

    addOrUpdateUser(user) {
        this.connection.onsuccess = () => {
            const db = this.connection.result
            const userStore = db
                .transaction(['users'], 'readwrite')
                .objectStore('users')

            const search = userStore.get(user.id)

            search.onsuccess = () => {
                if (search.result === undefined) userStore.add(user)
                else
                    Object.keys(user).forEach((key) => {
                        search[key] = user[key]
                    })
            }
        }
    }

    getUser(id) {
        this.connection.onsuccess = () => {
            const db = this.connection.result
            const userStore = db
                .transaction(['users'], 'readwrite')
                .objectStore('users')

            console.log(id)
            const search = userStore.get(id)
            search.onsuccess = () => {
                console.log(search)
                return search.result
            }
            search.onerror = () => {
                return undefined
            }
        }
    }
}

export class IndexDBGenericConnection {
    constructor() {
        this.connected = false
        this.connection = window.indexedDB.open('AutoIMDb', 1)

        this.connection.onerror = (error) => {
            console.error(error)
        }

        this.connection.onupgradeneeded = () => {
            const db = this.connection.result
            db.createObjectStore('data')
            db.createObjectStore('users', { keyPath: 'id' })
        }

        this.connection.onsuccess = () => {
            console.log('Successfully connected to indexDB')
            this.connected = true
        }
    }

    setData(key, data) {
        if (!this.connected) return

        const db = this.connection.result
        const transaction = db.transaction(['data'], 'readwrite')
        const store = transaction.objectStore('data')

        store.put(data, key)
    }

    getData(key) {
        return new Promise((resolve, reject) => {
            const db = this.connection.result

            if (!db) {
                reject(
                    new Error(
                        'Connection to the database has not been established'
                    )
                )
                return
            }

            const transaction = db.transaction(['data'], 'readwrite')
            const store = transaction.objectStore('data')

            const getRequest = store.get(key)

            getRequest.onsuccess = () => {
                const result = getRequest.result
                if (result === undefined) {
                    resolve({})
                } else {
                    resolve(result)
                }
            }

            getRequest.onerror = () => {
                resolve(undefined)
            }
        })
    }
}
