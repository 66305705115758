import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const StackedBarChartMainstream = ({ profile }) => {
    if (
        profile.ratings === undefined ||
        profile.ratings.ratings === undefined ||
        profile.ratings.ratings.length === 0
    )
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    const division = {}
    profile.ratings.ratings.forEach((rating) => {
        if (rating.type !== 'episode') {
            if (!Object.keys(division).includes(String(rating.releaseYear)))
                division[String(rating.releaseYear)] = {
                    other: 0,
                    mainstream: 0,
                }

            if (rating.votes < 50000)
                division[String(rating.releaseYear)].other++
            else division[String(rating.releaseYear)].mainstream++
        }
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Mainstream Division by Year',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')} - ${Math.round(
                                            (parseInt(yDatapoint.raw) /
                                                profile.ratings.byYear[
                                                String(yDatapoint.label)
                                                ]) *
                                            1000
                                        ) / 10
                                            }%`
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                            },
                            y: {
                                stacked: true,
                            },
                        },
                    }}
                    data={{
                        labels: Object.keys(profile.ratings.byYear),
                        datasets: [
                            {
                                label: 'Mainstream',
                                data: Object.keys(division).map(
                                    (year) => division[year].mainstream
                                ),
                                backgroundColor: 'lime',
                            },
                            {
                                label: 'Other',
                                data: Object.keys(division).map(
                                    (year) => division[year].other
                                ),
                                backgroundColor: 'red',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default StackedBarChartMainstream
