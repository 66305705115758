import React from 'react'
import { Grid, Box } from '@mui/material'

const UserCard = ({ profile, headline }) => {
    try {
        if (
            profile.contributions.placementsByTime === undefined ||
            profile.contributions.placementsByTime === null ||
            !Object.keys(
                profile.contributions.placementsByTime[
                profile.contributions.placementsByTime.length - 1
                ]
            ).includes(headline.toLowerCase()) ||
            profile.contributions.placementsByTime === undefined ||
            profile.contributions.placementsByTime[
                profile.contributions.placementsByTime.length - 1
            ][headline.toLowerCase()].username === undefined
        )
            return (
                <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                    <Box
                        className="m-auto"
                        sx={{
                            backgroundColor: 'rgb(33, 49, 60)',
                            padding: '1rem',
                            width: 'calc(100% - 2rem)',
                            height: '100%',
                            borderRadius: '0.5rem',
                            position: 'relative',
                        }}
                    >
                        <h3 style={{ color: 'white', textAlign: 'center' }}>
                            Data required, please refresh data to view
                        </h3>
                    </Box>
                </Grid>
            )

        const data =
            profile.contributions.placementsByTime[
            profile.contributions.placementsByTime.length - 1
            ][headline.toLowerCase()]

        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                        textAlign: 'center',
                    }}
                >
                    <Grid
                        container
                        columns={3}
                        spacing={0}
                        style={{ width: '100%' }}
                    >
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <h3
                                style={{
                                    color: 'white',
                                    fontFamily:
                                        'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                    textShadow: '0 0 7px #fff',
                                }}
                            >
                                {headline} User
                            </h3>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <h3
                                style={{
                                    color: 'white',
                                    fontFamily:
                                        'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                    textShadow: '0 0 7px #fff',
                                }}
                            >
                                {data ? data.username : 'N/A'}
                            </h3>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <h3
                                style={{
                                    color:
                                        headline.toLowerCase() === 'next'
                                            ? 'lime'
                                            : 'red',
                                    fontFamily:
                                        'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
                                    textShadow: '0 0 7px #fff',
                                }}
                            >
                                {data
                                    ? Math.abs(
                                        data.items -
                                        profile.contributions
                                            .placementsByTime[
                                            profile.contributions
                                                .placementsByTime.length -
                                            1
                                        ].items
                                    ).toLocaleString('en-US')
                                    : 0}{' '}
                                items
                            </h3>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        )
    } catch (_) {
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )
    }
}
export default UserCard
