import React from 'react'
import { Avatar, Stack, Chip } from '@mui/material'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

export const HeadlineComparison = ({
    profile1,
    profile2,
    setFirst,
    setOpen,
}) => {
    const chipStyles = {
        backgroundColor: '#63d67a', // Custom background color
        '&:hover': {
            backgroundColor: '#56c46c', // Custom hover background color
        },
        '& .MuiChip-deleteIcon': {
            color: 'rgba(255, 255, 255, 0.7)', // Custom delete icon color
        },
        '& .MuiChip-deleteIcon:hover': {
            color: '#ffffff', // Custom delete icon color on hover
        },
    }

    // * Change the users to compare
    const handleChange = (first) => {
        if (first) {
            setFirst(true)
            setOpen(true)
        } else {
            setFirst(false)
            setOpen(true)
        }
    }

    return (
        <h1>
            <Stack direction="row" spacing={1}>
                <Chip
                    onClick={() =>
                        window.open(
                            `https://www.imdb.com/user/${profile1.id}/`,
                            '_blank'
                        )
                    }
                    avatar={
                        <Avatar
                            alt={profile1.username}
                            src={
                                profile1.avatar !==
                                'https://images-na.ssl-images-amazon.com/images/M/MV5BMjQ4MTY5NzU2M15BMl5BanBnXkFtZTgwNDc5NTgwMTI@._V1_SY100_SX100_.jpg'
                                    ? profile1.avatar
                                    : ''
                            }
                        />
                    }
                    color="primary"
                    label={`${profile1.username}`}
                    // className="pointer"
                    deleteIcon={
                        <ChangeCircleIcon
                            titleAccess={
                                profile2 === undefined
                                    ? 'Select a profile to compare'
                                    : `Change profile from ${profile1.username}`
                            }
                        />
                    }
                    onDelete={() => handleChange(true)}
                    // variant="outlined"
                />
                <Avatar sx={{ bgcolor: 'red', height: 32, width: 32 }}>
                    Vs
                </Avatar>
                <Chip
                    onClick={() =>
                        window.open(
                            `https://www.imdb.com/user/${profile2.id}/`,
                            '_blank'
                        )
                    }
                    avatar={
                        <Avatar
                            alt={profile2.username}
                            src={
                                profile2.avatar !==
                                'https://images-na.ssl-images-amazon.com/images/M/MV5BMjQ4MTY5NzU2M15BMl5BanBnXkFtZTgwNDc5NTgwMTI@._V1_SY100_SX100_.jpg'
                                    ? profile2.avatar
                                    : ''
                            }
                        />
                    }
                    // color="primary"
                    label={`${profile2.username}`}
                    size="medium"
                    sx={chipStyles}
                    // className="pointer"
                    deleteIcon={
                        <ChangeCircleIcon
                            titleAccess={
                                profile2 === undefined
                                    ? 'Select a profile to compare'
                                    : `Change profile from ${profile2.username}`
                            }
                        />
                    }
                    onDelete={() => handleChange(false)}
                    // variant="outlined"
                />
            </Stack>
        </h1>
    )
}
