import React, { useState, useEffect, Fragment, useRef } from 'react'
import {
    Dialog,
    DialogActions,
    Button,
    Stepper,
    StepLabel,
    Step,
    CircularProgress,
    Tooltip,
    DialogContent,
    Typography,
    TextField,
    Box,
    LinearProgress,
    IconButton,
    Alert,
    AlertTitle,
    Snackbar,
    Grid,
    Paper,
    Avatar,
    DialogTitle,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Zoom from '@mui/material/Zoom'
import { apiRequestGet } from '../../../utils/api'
import { useNavigate } from 'react-router-dom'
import {
    secondsToFormatExtremelySimple,
    secondsToFormatSimple,
} from '../../../utils/time'
import { useCookies } from 'react-cookie'

const TooltipView = ({
    disableDialogButton,
    handleNextStep,
    steps,
    currentStep,
    loading,
}) => {
    if (disableDialogButton)
        return loading ? (
            <CircularProgress />
        ) : (
            <Tooltip
                title="Make sure you input a valid IMDb ID"
                arrow
                className="inputButtonView"
            >
                <span>
                    <Button
                        disabled={disableDialogButton}
                        className="inputButtonView"
                    >
                        {steps.length - 1 === currentStep
                            ? 'View Profile'
                            : 'Next Step'}
                    </Button>
                </span>
            </Tooltip>
        )

    return loading ? (
        <CircularProgress />
    ) : (
        <Button onClick={handleNextStep} disabled={disableDialogButton}>
            {steps.length - 1 === currentStep ? 'View Profile' : 'Next Step'}
        </Button>
    )
}

function LinearProgressWithLabel({ estimatedTime, value }) {
    const [countDown, setCountDown] = useState(estimatedTime)

    useEffect(() => {
        const interval = setInterval(() => {
            if (countDown <= 0) {
                setCountDown(0)
                return
            }
            setCountDown(countDown - 1)
        }, 1000)

        return () => clearInterval(interval)
    }, [countDown])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                    {`${secondsToFormatExtremelySimple(countDown)}`}
                </Typography>
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={value} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography
                    variant="body2"
                    color="text.secondary"
                >{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    )
}

const UserPreload = ({
    index,
    activeIndex,
    setActiveIndex,
    p,
    currentAPITime,
}) => {
    return (
        <Tooltip
            title={
                <Fragment>
                    <Typography variant="subtitle1">
                        Last Check:{' '}
                        {secondsToFormatSimple(
                            (currentAPITime -
                                new Date(p.scanCompletion).getTime()) /
                            1000
                        )}{' '}
                        ago
                    </Typography>
                    <Typography variant="subtitle1">
                        Ratings: ~{p.titles_rated?.toLocaleString('en-US')}
                    </Typography>
                    <Typography variant="subtitle1">
                        Views: {p.views.toLocaleString('en-US')}
                    </Typography>
                </Fragment>
            }
            arrow
            TransitionComponent={Zoom}
            onClick={() => {
                setActiveIndex(index)
            }}
        >
            <Grid xs={12} sm={6} md={4} item>
                <Paper
                    style={{
                        backgroundColor: '#1A2027',
                        padding: '.5rem',
                        cursor: 'pointer',
                        overflow: 'hidden',
                    }}
                    className={
                        activeIndex === index
                            ? 'hover-enlarge selected-background'
                            : 'hover-background hover-enlarge'
                    }
                >
                    <Avatar
                        alt={p.username}
                        src={p.avatar_url}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '4rem',
                            height: '4rem',
                        }}
                        className="undraggable"
                    />
                    <Typography
                        style={{
                            textAlign: 'center',
                            color: 'white',
                        }}
                        className="undraggable"
                    >
                        {p.username}
                    </Typography>
                </Paper>
            </Grid>
        </Tooltip>
    )
}

const ProfileDialog = ({
    profile,
    setProfile,
    open,
    setOpen,
    suggestionsActive,
    setSuggestionsActive,
    preProcessed,
    currentAPITime,
}) => {
    const navigate = useNavigate()

    // * Save quickselect
    const [cookies, setCookies] = useCookies(['quickSelect'])
    const [ran, setRan] = useState(false)
    useEffect(() => {
        setCookies('quickSelect', true)
    }, [setCookies])
    useEffect(() => {
        if (!ran) {
            setSuggestionsActive(cookies.quickSelect === 'true')
            setRan(true)
        }
    }, [cookies.quickSelect, ran, setSuggestionsActive])

    // * useState - Variables
    const profileIsSet = profile === {} || Object.keys(profile).length === 0
    const [imdbIdInput, setImdbIdInput] = useState('')
    const [disableDialogButton, setDisableDialogButton] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [estimatedTime, setEstimatedTime] = useState(0)

    const [progress, setProgress] = useState(0)
    const intervalPointer = useRef()
    useEffect(() => {
        if (activeStep === 1 && progress === 0 && loading) {
            if (estimatedTime === 0) return
            intervalPointer.current = setInterval(() => {
                setProgress((prevProgress) =>
                    prevProgress >= 99 ? 99 : prevProgress + 1
                )
            }, estimatedTime * 10)
        }
    }, [activeStep, estimatedTime, loading, progress])

    // * Toggle content of Find User
    const [activeIndex, setActiveIndex] = useState(undefined)
    const toggleFindUserContent = () => {
        setSuggestionsActive(!suggestionsActive)
        setCookies('quickSelect', !suggestionsActive)
    }

    // * Dialog Next Button
    useEffect(() => {
        setDisableDialogButton(
            (suggestionsActive && activeIndex === undefined) ||
            (!suggestionsActive &&
                (imdbIdInput === undefined ||
                    !imdbIdInput.startsWith('ur') ||
                    imdbIdInput.length < 9 ||
                    imdbIdInput.length > 11 ||
                    isNaN(imdbIdInput.substring(2))))
        )
    }, [imdbIdInput, activeIndex, suggestionsActive])

    // * Snackbar
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState(false)
    const [alertContent, setAlertContent] = useState('')
    const handleCloseAlertSnackbar = () => {
        setAlertSnackbarOpen(false)
    }

    // * Dialog
    const steps = ['Find User', 'Process Profile', 'View Profile']
    const handleClose = () => {
        setLoading(false)
        setActiveStep(0)
        setProgress(0)
        clearInterval(intervalPointer.current)
        setImdbIdInput('')
        setActiveIndex(undefined)
        setOpen(false)
    }

    const handleNext = async () => {
        if (activeStep === 0) {
            const idCheck = suggestionsActive
                ? preProcessed[activeIndex].id
                : imdbIdInput
            setLoading(true)
            const res = await apiRequestGet(`/user-exists?id=${idCheck}`)
            if (res.exists) {
                localStorage.setItem(
                    'selectedUser',
                    JSON.stringify({
                        id: idCheck,
                        startTime: new Date(),
                        estimatedTime: res.estimatedTime,
                        estimatedFinish: new Date(
                            new Date().getTime() + res.estimatedTime * 1000
                        ),
                    })
                )
                // localStorage.setItem('viewingUser', idCheck)
                setEstimatedTime(res.estimatedTime)
                setActiveStep(activeStep + 1)
                const usr = await apiRequestGet(
                    `/imdb-profile-stats?profile=${idCheck}`
                )
                if (Object.keys(usr).includes('error')) {
                    setAlertContent(() => {
                        return (
                            <>
                                Experiencing error: <b>{usr.error}</b>
                            </>
                        )
                    })
                    setAlertSnackbarOpen(true)
                    setLoading(false)
                    setActiveStep(0)
                    return
                }
                // TODO: Add check for error
                handleClose()
                setProfile(usr)
            } else if (res.error !== 0) {
                setAlertContent(() => {
                    return (
                        <>
                            Experiencing error code:{' '}
                            <strong>{res.error}</strong>, wait a few minutes
                            before trying again
                        </>
                    )
                })
                setAlertSnackbarOpen(true)
                setLoading(false)
            } else {
                setAlertContent(() => {
                    return (
                        <>
                            <strong>Invalid IMDb ID</strong>, try again with a
                            new{' '}
                            <a
                                target="_blank"
                                href="https://gyazo.com/036305afc9ef9fb742694472129b5ac9"
                                rel="noreferrer"
                            >
                                IMDb ID
                            </a>
                        </>
                    )
                })
                setAlertSnackbarOpen(true)
                setLoading(false)
            }
        }
    }

    // * Render content of active step
    const renderSwitch = ({ activeStep }) => {
        switch (activeStep) {
            case 0:
                return !suggestionsActive ? (
                    <>
                        <DialogTitle>Search for a Profile</DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom style={{ color: 'gray' }}>
                                This page is designed for viewing an IMDb
                                profile in a different way, with focus on
                                viewing statistics about the profile, giving a
                                better insight into the achievements/badges, and
                                to view contributions made to IMDb. This might
                                take some time depending on the amount of
                                ratings, reviews and other factors. After
                                clicking View the profile will be loaded, and an
                                estimated time will be shown.
                            </Typography>
                            <TextField
                                fullWidth
                                placeholder="IMDb ID"
                                onChange={(e) => {
                                    setImdbIdInput(e.target.value)
                                }}
                                className="whiteTextInput"
                            />
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <DialogTitle>Quick-select</DialogTitle>
                        <DialogContent dividers>
                            <Box sx={{ width: '100%' }}>
                                <Grid container spacing={1}>
                                    {preProcessed.slice(0, 9).map((p, index) => (
                                        <UserPreload
                                            key={index}
                                            index={index}
                                            activeIndex={activeIndex}
                                            setActiveIndex={setActiveIndex}
                                            p={p}
                                            currentAPITime={currentAPITime}
                                        />
                                    ))}
                                </Grid>
                            </Box>
                        </DialogContent>
                    </>
                )
            case 1:
                return (
                    <>
                        <DialogTitle>Loading Profile...</DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom style={{ color: 'gray' }}>
                                This page will load for a while depending on
                                amount of ratings and information on the
                                profile. (ratings, reviews etc.)
                            </Typography>
                            <Typography>
                                «You can make suggestions for more info by
                                hitting the contact button bottom right on any
                                other pages»-Nomissimon10
                            </Typography>
                            <LinearProgressWithLabel
                                value={progress}
                                estimatedTime={estimatedTime}
                            />
                        </DialogContent>
                    </>
                )
            default:
                return null
        }
    }

    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth={'sm'} scroll="paper">
                {!profileIsSet && (
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}

                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {renderSwitch({ activeStep })}
                <DialogActions>
                    <Button onClick={() => navigate('/')}>Dashboard</Button>
                    {!profileIsSet && (
                        <Button onClick={handleClose}>Close</Button>
                    )}
                    {activeStep === 0 && (
                        <Button onClick={toggleFindUserContent}>
                            {suggestionsActive ? 'Search' : 'Quick-select'}
                        </Button>
                    )}
                    <TooltipView
                        disableDialogButton={disableDialogButton}
                        handleNextStep={handleNext}
                        steps={steps}
                        currentStep={activeStep}
                        loading={loading}
                    />
                </DialogActions>
            </Dialog>

            <Snackbar
                open={alertSnackbarOpen}
                onClose={handleCloseAlertSnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    severity="warning"
                    variant="filled"
                    onClose={handleCloseAlertSnackbar}
                >
                    <AlertTitle>Warning</AlertTitle>
                    {alertContent}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ProfileDialog
