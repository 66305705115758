import React, { useState, useEffect } from 'react'
import './CSS/App.css'
import { useCookies } from 'react-cookie'
import {
    Login,
    Logout,
    PrivacyPolicy,
    Signup,
    Information,
    NewPodcast,
    StatBoard,
    Status,
    Dashboard,
    AcceptPodcast,
    UsersBoard,
    NotFound404,
    ProfileStats,
    VerifyMail,
    ContributorLeaderboard,
    ContributionLog,
    Settings,
    AcceptShows,
    ProfileComparison,
    UsernameSearch,
    SimonDidIt,
} from './pages'
import Sidebar from './components/Navigation/Sidebar'
import Navbar from './components/Navigation/Navbar'
import PageSpeedDial from './components/Navigation/PageSpeedDial'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { apiRequestGet, apiRequestPost } from './utils/api.js'
import { theme } from './theme'
import { ThemeProvider } from '@mui/material'
import { styled } from '@mui/material/styles'
import AppIcon from './resources/images/logo.png'

import { PrivacyPolicyHandel, HandelDeleteUser } from './handel'
import { ContributorStats } from './pages/ContributorStats.js'
import TriggerFire from './pages/TriggerFire'

const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
        paddingLeft: 280,
    },
}))

function App() {
    const [cookies] = useCookies(['jwt'])

    const [me, setMe] = useState({})
    useEffect(() => {
        async function fetchMe() {
            if (cookies.jwt === undefined || cookies.jwt === 'undefined') {
                setMe({ isLoggedIn: false, role: 'user' })
            } else {
                const isLogged = await apiRequestPost('/me', {}, cookies)
                if (isLogged.isLoggedIn) setMe(isLogged)
                else setMe({ isLoggedIn: false, role: 'user' })
            }
        }
        fetchMe()
    }, [cookies])

    // * DASHBOARD DATA
    const [data, setData] = useState([])
    useEffect(() => {
        const getStatData = async () => {
            const statData = await apiRequestGet('/stats')
            if (Object.keys(statData).includes('error')) {
                console.error(`Failed to fetch stats: ${statData.error}`)
                return
            }
            setData(statData)
        }

        getStatData()
    }, [setData])

    // * USERS
    const [user_data, setUserData] = useState([])
    // * COVERAGE
    const [coverage_data, setCoverageData] = useState([])

    useEffect(() => {
        const fetchUserData = async () => {
            const userData = await apiRequestGet('/contribution-leaderboard')
            setUserData(userData)
        }

        const fetchCoverageData = async () => {
            const userData = await apiRequestGet('/coverage-stats')
            setCoverageData(userData)
        }

        fetchUserData()
        fetchCoverageData()
    }, [])

    // * SIDEBAR
    const [isSidebarOpen, setSidebarOpen] = useState(false)

    //* Status Data
    const init = {
        API: {
            undertitle: 'API Server',
            running: false,
        },
        Core: {
            undertitle: 'Core',
            running: null,
        },
        Forums: {
            undertitle: 'Sprinklr Forums',
            running: null,
        },
        IMDb: {
            undertitle: 'IMDb Website',
            running: null,
        },
        Database: {
            undertitle: 'MongoDB',
            running: null,
        },
    }
    const [statuses, setStatuses] = useState(init)

    useEffect(() => {
        async function fetchStatus() {
            const req = await apiRequestGet('/status')
            if (!req.error && req) setStatuses(req)
        }
        fetchStatus()
    }, [])

    // * Before unload
    const [showOverlay, setShowOverlay] = useState(false)
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            setShowOverlay(true)
        };

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            setShowOverlay(false)
        }
    }, [])

    return (
        <>
            {showOverlay && (
                <div className="w-full bg-black absolute" style={{ height: '100vh', zIndex: 1000000 }}>
                    <img src={AppIcon} className="logo shadow" alt="App Icon" />
                </div>
            )}

            <main style={{ position: 'relative', height: '100vh' }}>
                <Router>
                    <ThemeProvider theme={theme}>
                        <LayoutRoot>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <Dashboard data={data} setData={setData} />
                                    }
                                />

                                <Route
                                    path="/stats"
                                    element={
                                        <StatBoard
                                            coverage_data={coverage_data}
                                            data={data}
                                        />
                                    }
                                />

                                <Route
                                    path="/contribution-log"
                                    element={<ContributionLog />}
                                />

                                {me.isLoggedIn && (
                                    <>
                                        <Route
                                            path="/verify-podcasts"
                                            element={<AcceptPodcast />}
                                        />
                                        <Route
                                            path="/verify-shows"
                                            element={<AcceptShows />}
                                        />
                                    </>
                                )}

                                <Route
                                    path="/signup"
                                    element={<Signup user={me} />}
                                />

                                <Route
                                    path="/login"
                                    element={<Login user={me} />}
                                />

                                {me.isLoggedIn && me.role === 'admin' && (
                                    <Route
                                        path="/new-podcast"
                                        element={<NewPodcast />}
                                    />
                                )}

                                <Route
                                    path="/privacy-policy"
                                    element={<PrivacyPolicy />}
                                />

                                <Route
                                    path="/top-10-contributors"
                                    element={<UsersBoard user_data={user_data} />}
                                />

                                <Route
                                    path="/information"
                                    element={<Information />}
                                />

                                <Route
                                    path="/status"
                                    element={<Status statuses={statuses} />}
                                />

                                <Route path="/logout" element={<Logout />} />

                                {/* Start of Public Interest Section */}
                                <Route
                                    path="/imdb-username-search"
                                    element={<UsernameSearch />}
                                />

                                <Route
                                    path="/did-simon-do-it"
                                    element={<SimonDidIt />}
                                />

                                <Route
                                    path="/profile-viewer"
                                    element={<ProfileStats />}
                                />

                                <Route
                                    path="/profile-comparison"
                                    element={<ProfileComparison />}
                                />

                                <Route
                                    path="/contributor-leaderboard"
                                    element={<ContributorLeaderboard />}
                                />

                                <Route
                                    path="/contributors-top-placements"
                                    element={<ContributorStats />}
                                />
                                {/* End of Public Interest Section */}

                                {me.isLoggedIn && (
                                    <Route
                                        path="/settings"
                                        element={
                                            <Settings
                                                setting={{
                                                    email: me.settings.email,
                                                    username: me.settings.username,
                                                }}
                                            />
                                        }
                                    />
                                )}

                                <Route path="/verify" element={<VerifyMail />} />

                                {
                                    me.isLoggedIn &&
                                    me.role === 'admin' &&
                                    <Route path="/trigger-fire" element={<TriggerFire />} />
                                }

                                <Route path="/privacy-policy-handel" element={<PrivacyPolicyHandel />} />

                                <Route path="/handel-delete-user" element={<HandelDeleteUser />} />

                                <Route
                                    path="*"
                                    exact={true}
                                    element={<NotFound404 />}
                                />
                            </Routes>

                            <Navbar
                                user={me}
                                onSidebarOpen={() => setSidebarOpen(true)}
                            />
                            <Sidebar
                                user={me}
                                onClose={() => setSidebarOpen(false)}
                                open={isSidebarOpen}
                            />

                            <PageSpeedDial />
                        </LayoutRoot>
                    </ThemeProvider>
                </Router>
            </main>
        </>
    )
}

export default App
