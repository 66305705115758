import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import { FaStar } from 'react-icons/fa'

export const WatchlistComparison = ({
    items,
    profile1,
    profile2,
    profile1Watchlist,
    profile2Watchlist,
}) => {
    // * Holds the state of the expanded button
    const containerRef = useRef(null)
    const [expanded, setExpanded] = useState(false)
    const [numItems, setNumItems] = useState(5) // Number of items initially displayed

    useEffect(() => {
        const handleResize = () => {
            try {
                const containerWidth = containerRef.current.offsetWidth
                const itemWidth = 177.5 // Adjust this value according to your item width
                const newNumItems = Math.floor(containerWidth / itemWidth)
                setNumItems(newNumItems)
            } catch (_) {}
        }

        window.addEventListener('resize', handleResize)
        handleResize() // Initial calculation on mount

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const handleExpand = () => {
        setExpanded(!expanded)
    }

    return (
        <>
            <h4>
                What to watch Together?{' '}
                {numItems >= items.length ? (
                    ''
                ) : (
                    <>
                        {'('}
                        <u onClick={handleExpand} className="pointer">
                            {expanded ? 'Collapse' : 'Expand'}
                        </u>
                        {')'}
                    </>
                )}
            </h4>
            {profile2Watchlist === undefined && (
                <p>
                    {profile2.username} must set their watchlist to public to
                    use this feature
                </p>
            )}
            {profile1Watchlist === undefined && (
                <p>
                    {profile1.username} must set their watchlist to public to
                    use this feature
                </p>
            )}
            <Grid
                container
                spacing={2.5}
                rowSpacing={expanded ? 2.5 : 0.5}
                justifyContent="center"
                alignItems="center"
                ref={containerRef}
                className="comparison-grid"
            >
                {
                    // * Display the first 5 items
                    items.slice(0, numItems).map((title) => {
                        return (
                            <Grid item key={title.id}>
                                <a
                                    href={`https://imdb.com/title/${title.id}/`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        src={`https://m.media-amazon.com/images/M/${title.image_url}`}
                                        title={title.title}
                                        alt={title.title}
                                        className="comparison-image"
                                    />
                                </a>
                                <div className="comparison-title">
                                    <a
                                        href={`https://imdb.com/title/${title.id}/`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {title.title}
                                    </a>
                                </div>
                                <div className="comparison-ratings">
                                    <span title="Average Rating">
                                        <FaStar style={{ color: 'orange' }} />{' '}
                                        {title.averageRating}
                                    </span>
                                </div>
                            </Grid>
                        )
                    })
                }
                {
                    // * Display the remaining items
                    expanded &&
                        items.slice(numItems, items.length).map((title) => {
                            return (
                                <Grid item key={title.id}>
                                    <a
                                        href={`https://imdb.com/title/${title.id}/`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={`https://m.media-amazon.com/images/M/${title.image_url}`}
                                            title={title.title}
                                            alt={title.title}
                                            className="comparison-image"
                                        />
                                    </a>
                                    <div className="comparison-title">
                                        <a
                                            href={`https://imdb.com/title/${title.id}/`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {title.title}
                                        </a>
                                    </div>
                                    <div className="comparison-ratings">
                                        <span title="Average Rating">
                                            <FaStar
                                                style={{ color: 'orange' }}
                                            />{' '}
                                            {title.averageRating}
                                        </span>
                                    </div>
                                </Grid>
                            )
                        })
                }
                {
                    // * Display empty items to fill the grid
                    <Grid
                        item
                        xs={
                            (12 / numItems) *
                            (numItems - (items.length % numItems))
                        }
                    />
                }
            </Grid>
        </>
    )
}
