import React, { useRef, useEffect } from 'react'

export const SearchInput = ({ searchWord, setSearchWord, search, placeholder }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, [searchWord]);

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            console.log('Searching');
            search();
        }
    };

    return (
        <input
            ref={inputRef}
            className="searchbar-input"
            placeholder={placeholder}
            type="text"
            onKeyDown={_handleKeyDown}
            value={searchWord}
            onChange={(event) => setSearchWord(event.target.value)}
        />
    )
}