export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

export const secondsToFormatExtremelySimple = (seconds) => {
    let days = 0
    let hours = 0
    let minutes = 0
    seconds = Math.floor(seconds)

    while (seconds >= 60) {
        if (seconds > 24 * 60 * 60) {
            days++
            seconds -= 24 * 60 * 60
        } else if (seconds > 60 * 60) {
            hours++
            seconds -= 60 * 60
        } else {
            minutes++
            seconds -= 60
        }
    }

    if (days > 0) return `${days}d`
    else if (hours > 0) return `${hours}h`
    else if (minutes > 0) return `${minutes}m`
    else return `${seconds}s`
}

export const secondsToFormatSimple = (seconds) => {
    let days = 0
    let hours = 0
    let minutes = 0
    seconds = Math.floor(seconds)

    while (seconds >= 60) {
        if (seconds > 24 * 60 * 60) {
            days++
            seconds -= 24 * 60 * 60
        } else if (seconds > 60 * 60) {
            hours++
            seconds -= 60 * 60
        } else {
            minutes++
            seconds -= 60
        }
    }

    if (days > 0) return `${days} days`
    else if (hours > 0) return `${hours} hours`
    else if (minutes > 0) return `${minutes} min`
    else return `${seconds} sec`
}

export const secondsToFormat = (seconds) => {
    let days = 0
    let hours = 0
    let minutes = 0
    seconds = Math.floor(seconds)

    while (seconds >= 60) {
        if (seconds > 24 * 60 * 60) {
            days++
            seconds -= 24 * 60 * 60
        } else if (seconds > 60 * 60) {
            hours++
            seconds -= 60 * 60
        } else {
            minutes++
            seconds -= 60
        }
    }

    if (days > 0) return `${days} days, ${hours} hours and ${minutes} min`
    else if (hours > 0) return `${hours} hours and ${minutes} min`
    else if (minutes > 0) return `${minutes} min and ${seconds} sec`
    else return `${seconds} sec`
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

export function indexedMonthToText(indexedMonth) {
    return months[indexedMonth]
}
