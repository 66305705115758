import React from 'react';
import { Grid, Box } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const PieChartOriginofCountry = ({ profile }) => {
    return (
        <Grid
            item
            xs={2}
            sm={2}
            md={1}
            lg={1}
            xl={1}
        >
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem'
                }}
            >
                <h1>Distribution of Country of Origin</h1>
                {/* <Pie
                    style={{
                        height: '100%'
                    }}
                    data={{
                        labels: labels,
                        datasets: [
                            {
                                data: [
                                    12,
                                    13,
                                    5
                                ],
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(255, 99, 132, 1)',
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 206, 86, 1)'
                                ]
                            }
                        ]
                    }}
                /> */}
            </Box>
        </Grid>
    );
}

export default PieChartOriginofCountry;