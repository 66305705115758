import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    Typography,
    Avatar,
    ToggleButtonGroup,
    ToggleButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
    IconButton,
    Slider,
} from '@mui/material'
import {
    deepOrange,
    deepPurple,
    green,
    grey,
    brown,
    amber,
} from '@mui/material/colors'
import CloseIcon from '@mui/icons-material/Close'
import { Bar } from 'react-chartjs-2'
import { GradientCircularProgress } from '../components/UsernameSearch/GradientCircularProgress'
import { indexToMonth, monthYearSorting, monthYearToValue } from '../utils/dates'
import { apiRequestGet } from '../utils/api'
import { IndexDBGenericConnection } from '../utils/IndexDBConnection.js'
import { makeAbbr } from '../utils/profile.js'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const indexDb = new IndexDBGenericConnection()
const colors = [green, deepOrange, deepPurple, grey, brown, amber]

export const ContributorStats = () => {
    document.title = 'AutoIMDb | Contributor Stats'

    const [loading, setLoading] = useState(true)
    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])
    const [allTime, setAlltime] = useState([])
    const [stats, setStats] = useState([])
    const [view, setView] = useState(0)
    const [items, setItems] = useState([])
    const [statPaneOpen, setStatePaneOpen] = useState(false)

    const [viewYears, setViewYears] = useState([0, 0])
    const [actualYears, setActualYears] = useState([])
    const handleChangeViewYears = (event, newValue) => {
        setViewYears(newValue)
    }

    useEffect(() => {
        const fetch = async () => {
            setLoading(true)
            const response = await indexDb.getData('contributor-stats-top-placements')
            if (response !== undefined && Object.keys(response).length > 0 && response.time.getTime() > new Date().getTime() - 24 * 60 * 60 * 1000) {
                setMonths(response.months)
                setYears(response.years)
                setAlltime(response.alltime)
                setStats(response.stats)
                setLoading(false)
                return
            }

            const mResult = (await axios.get('https://contributor.autoimdb.com/leaderboard-months')).data.leaderboard
            mResult.forEach(usr => usr.abbr = makeAbbr(usr.username))
            setMonths(mResult)
            const yResult = (await axios.get('https://contributor.autoimdb.com/leaderboard-years')).data.leaderboard
            yResult.forEach(usr => usr.abbr = makeAbbr(usr.username))
            setYears(yResult)
            const top25Alltime = (await axios.get('https://contributor.autoimdb.com/alltime-estimates')).data
                .filter(usr =>
                    usr.byYear[(new Date().getFullYear()).toString()].rank <= 25
                )
            top25Alltime.sort((a, b) => a.byYear[(new Date().getFullYear()).toString()].rank - b.byYear[(new Date().getFullYear()).toString()].rank)
            const promises = []
            top25Alltime.forEach(usr => {
                promises.push(apiRequestGet(`/user-info-profile?id=${usr.id}`))
            })
            const data = await Promise.all(promises)
            const top25SimplifiedAlltime = top25Alltime.map((usr, index) => {
                return {
                    id: usr.id,
                    username: (data[index] && data[index].user) ? data[index].user.username : usr.username,
                    items: usr.byYear[(new Date().getFullYear()).toString()].total,
                    profilePicture: (data[index] && data[index].user) ? data[index].user.profilePicture : '',
                    abbr: makeAbbr((data[index] && data[index].user) ? data[index].user.username : usr.username)
                }
            })
            setAlltime(top25SimplifiedAlltime)
            let statsResult = await apiRequestGet('/registration-statistics')
            statsResult.sort(monthYearSorting)
            statsResult = statsResult.filter(item => item._id !== '' && item._id !== 'DELETED' && parseInt(item._id.split(" ")[1]) >= 1990)
            setStats(statsResult)

            await indexDb.setData('contributor-stats-top-placements', { months: mResult, years: yResult, alltime: top25SimplifiedAlltime, stats: statsResult, time: new Date() })
            setLoading(false)
        }
        if (indexDb.connected)
            fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexDb.connected])

    useEffect(() => {
        if (view === 1)
            setItems(years)
        else if (view === 0)
            setItems(months)
        else
            setItems(allTime)
    }, [months, view, years, allTime])

    useEffect(() => {
        if (stats.length > 0) {
            setActualYears(Array.from(new Set(stats.map(item => parseInt(item._id.split(' ')[1])))))
            setViewYears([Math.floor(monthYearToValue(stats.at(0)._id) / 12), Math.floor(monthYearToValue(stats.at(-1)._id) / 12)])
        }
    }, [stats])

    const [dataLineChart, setDataLineChart] = useState({
        labels: [],
        datasets: []
    })
    
    useEffect(() => {
        const data = {
            labels: [],
            datasets: [
                { label: 'Old', data: [], backgroundColor: 'rgba(255, 0, 0, 1)' },
                { label: 'New', data: [], backgroundColor: 'rgba(57, 255, 20, 1)' }
            ]
        }

        let total = 0
        stats.forEach(item => {
            if (viewYears[0] <= Math.floor(monthYearToValue(item._id) / 12) && viewYears[1] >= Math.floor(monthYearToValue(item._id) / 12)) {
                data.labels.push(item._id)
                data.datasets[0].data.push(total)
                data.datasets[1].data.push(item.count)
            }
            total += item.count
        })
        setDataLineChart(data)
    }, [stats, viewYears])

    const options = {
        animation: true,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: true },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    }

    return (
        <div className="w-full inline-block box-border" style={{ minHeight: 'calc(100vh - 64px)' }}>
            <div className="w-full h-full bg-repeat-y" style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                color: '#8d9399',
                padding: '10px 0px',
                overflowWrap: 'anywhere',
            }}>
                {loading ?
                    <div className="flex items-center justify-center">
                        <GradientCircularProgress size="15rem" />
                    </div>
                    :
                    <div
                        className="mx-auto"
                        style={{
                            maxWidth: '880px',
                        }}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={view}
                            exclusive
                            onChange={(e, newView) => { if ([0, 1, 2].includes(newView)) setView(newView) }}
                            className="my-8 w-full"
                        >
                            <ToggleButton value={0}>Month</ToggleButton>
                            <ToggleButton value={1}>Year</ToggleButton>
                            <ToggleButton value={2}>Alltime</ToggleButton>
                            <ToggleButton value={10} onClick={() => setStatePaneOpen(true)}>Registration Stats</ToggleButton>
                        </ToggleButtonGroup>

                        <Dialog
                            open={statPaneOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={() => setStatePaneOpen(false)}
                            fullWidth={true}
                            maxWidth={'calc(100% - 64px)'}
                            style={{ height: 'calc(100% - 64px)' }}
                            PaperProps={{
                                sx: {
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'black'
                                }
                            }}
                        >
                            <DialogTitle sx={{ color: 'white' }}>IMDb Signup Stats</DialogTitle>
                            <Slider
                                style={{
                                    marginTop: '.75rem',
                                    width: 'calc(100% - 5rem)',
                                }}
                                className="mx-auto"
                                id="signupStats"
                                getAriaLabel={() => 'Year range'}
                                value={viewYears}
                                onChange={handleChangeViewYears}
                                min={
                                    actualYears.length > 0
                                        ? actualYears.at(0)
                                        : null
                                }
                                max={
                                    actualYears.length > 0
                                        ? actualYears.at(-1)
                                        : null
                                }
                                marks={actualYears.map((item, index) => { return (index === 0 || index === actualYears.length - 1) ? { value: item, label: item } : { value: item } })}
                                step={null}
                                valueLabelDisplay="auto"
                            />
                            <IconButton
                                aria-label="close"
                                onClick={() => setStatePaneOpen(false)}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <DialogContent>
                                <Bar
                                    data={dataLineChart}
                                    options={options}
                                />
                            </DialogContent>
                        </Dialog>

                        {items.map((item, index) => {
                            const bg_color = index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? '#CD7F32' : 'black'
                            let value = 0
                            for (let i = 0; i < item.abbr.length; i++) value += item.abbr.charCodeAt(i)
                            let colorIndex = value % colors.length

                            return <div
                                key={index}
                                className="w-full mb-2 rounded-lg cursor-pointer items-start flex relative border border-solid border-gray-500"
                                style={{
                                    backgroundColor: 'rgba(21, 31, 47, 0.8)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(21, 31, 47, 1)',
                                        transform: 'scale(1.05)',
                                    },
                                    transition: 'background-color 0.8s, transform 0.8s',
                                }}
                            >
                                <div
                                    className="rounded-s-lg mr-2 text-center text-white flex justify-center items-center w-12"
                                    style={{
                                        backgroundColor: bg_color,
                                        height: 'calc(40px + 0.5rem)',
                                    }}
                                >
                                    <Typography>{index + 1}</Typography>
                                </div>
                                <div className="py-1 pt-1">
                                    <Avatar alt={`${item.username} Avatar`} sx={{ bgcolor: colors[colorIndex][500] }} src={item.profilePicture ? item.profilePicture : ''}>{item.abbr}</Avatar>
                                </div>
                                <div className="my-auto text-white ml-2">
                                    <Typography className="whitespace-nowrap truncate">
                                        {item.username}
                                    </Typography>
                                </div>
                                {
                                    view !== 2 &&
                                    <div className="my-auto text-white ml-auto mr-2">
                                        <Typography>{view === 0 ? indexToMonth(item.month - 1) : ''} {item.year}</Typography>
                                    </div>
                                }
                                <div
                                    style={{
                                        ...(view === 2 && { marginLeft: 'auto' }),
                                        flex: '0 0 100px',
                                        backgroundColor: bg_color,
                                        height: 'calc(40px + 0.5rem)',
                                    }}
                                    className="my-auto rounded-r-lg text-white flex text-center justify-center align-middle items-center"
                                >
                                    <Typography>{item.items.toLocaleString('en-US')}</Typography>
                                </div>
                            </div>
                        }
                        )}
                    </div>
                }
            </div>
        </div>
    )
}