import React, { useState } from 'react';
import {
    ResponsiveContainer,
    Pie,
    Cell,
    PieChart,
    Sector
} from 'recharts';
import { Grid } from '@mui/material';

const EpisodeDonutChart = ({ coverage_data }) => {
    let episodePieDataset = [];

    const COLORS = ['rgb(54, 162, 235)', 'rgb(51, 255, 51)'];

    if (coverage_data !== undefined && Object.keys(coverage_data).includes('coverage')) {
        let total_episodes = [];
        let uploaded_episodes = [];
        coverage_data["coverage"].forEach(document => {
            total_episodes.push(document.total_episodes);
            uploaded_episodes.push(document.total_episodes - document.remaining_episodes);
        });

        // Add data for pie
        episodePieDataset.push({
            name: 'Remaining Episodes',
            value: total_episodes[total_episodes.length - 1] - uploaded_episodes[total_episodes.length - 1]
        });
        episodePieDataset.push({
            name: 'Uploaded Episodes',
            value: uploaded_episodes[total_episodes.length - 1]
        });
    }

    // Render pie chart
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#FFFFFF">{`${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    const [state, setState] = useState({
        activeIndex: 1
    });

    function onPieEnter(_, index) {
        setState({
            activeIndex: index,
        });
    };

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <ResponsiveContainer>
                <PieChart
                    className="backgroundDark">
                    <Pie
                        data={episodePieDataset}
                        activeIndex={state.activeIndex}
                        activeShape={renderActiveShape}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius="auto"
                        innerRadius="50%"
                        fill="#8884d8"
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                    >
                        {episodePieDataset.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Grid>
    );
}

export default EpisodeDonutChart;