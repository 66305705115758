import React from 'react'
import {
    Card,
    Grid,
    // CardMedia,
    CardContent,
    Typography,
    CardActionArea,
} from '@mui/material'
import { secondsToFormatSimple } from '../../utils/time'

const ContributionCard = ({ data }) => {
    let { timestamp } = data
    const {
        uploadedEpisodes,
        processedEpisodes,
        runtimeMs,
        contributionsPerS,
    } = data

    if (typeof timestamp !== 'string')
        timestamp = `${timestamp.getFullYear()}-${`${
            timestamp.getMonth() + 1
        }`.padStart(2, '0')}-${`${timestamp.getDate()}`.padStart(
            2,
            '0'
        )} ${`${timestamp.getHours()}`.padStart(
            2,
            '0'
        )}:${`${timestamp.getMinutes()}`.padStart(2, '0')}`

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card>
                <CardActionArea>
                    {/* <CardMedia
                        component="img"
                        height="100"
                        image={chart_illustration}
                        alt="CardImage"
                    /> */}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {timestamp} -{' '}
                            {secondsToFormatSimple(runtimeMs / 1000)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <b>Uploaded Episodes:</b>{' '}
                            {uploadedEpisodes.toLocaleString('en-US')}
                            <br />
                            <b>Processed Episodes:</b>{' '}
                            {processedEpisodes.toLocaleString('en-US')}
                            <br />
                            <b>Contributions Per Second:</b>{' '}
                            {Math.round(contributionsPerS * 10) / 10}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}
export default ContributionCard
