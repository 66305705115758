import React, { Fragment, useEffect, useState } from 'react'
import {
    Dialog,
    DialogActions,
    Button,
    Grid,
    DialogTitle,
    DialogContent,
    Box,
    Avatar,
    Typography,
    Tooltip,
    Paper,
    CircularProgress,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { secondsToFormatSimple } from '../../utils/time'
import Zoom from '@mui/material/Zoom'

const UserPreload = ({
    index,
    activeIndex,
    setActiveIndex,
    p,
    currentAPITime,
}) => {
    return (
        <Tooltip
            title={
                <Fragment>
                    <Typography variant="subtitle1">
                        Last Check:{' '}
                        {secondsToFormatSimple(
                            (currentAPITime -
                                new Date(p.scanCompletion).getTime()) /
                                1000
                        )}{' '}
                        ago
                    </Typography>
                    <Typography variant="subtitle1">
                        Ratings: ~{p.titles_rated?.toLocaleString('en-US')}
                    </Typography>
                    <Typography variant="subtitle1">
                        Views: {p.views.toLocaleString('en-US')}
                    </Typography>
                </Fragment>
            }
            arrow
            TransitionComponent={Zoom}
            onClick={() => {
                setActiveIndex(index)
            }}
        >
            <Grid xs={12} sm={6} md={4} item>
                <Paper
                    style={{
                        backgroundColor: '#1A2027',
                        padding: '.5rem',
                        cursor: 'pointer',
                        overflow: 'hidden',
                    }}
                    className={
                        activeIndex === index
                            ? 'hover-enlarge selected-background'
                            : 'hover-background hover-enlarge'
                    }
                >
                    <Avatar
                        alt={p.username}
                        src={p.avatar_url}
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '4rem',
                            height: '4rem',
                        }}
                        className="undraggable"
                    />
                    <Typography
                        style={{
                            textAlign: 'center',
                            color: 'white',
                        }}
                        className="undraggable"
                    >
                        {p.username}
                    </Typography>
                </Paper>
            </Grid>
        </Tooltip>
    )
}

export const ProfileDialog = ({
    open,
    setOpen,
    first,
    selected1,
    selected2,
    possible,
    currentAPITime,
    setProfile1,
    setProfile2,
}) => {
    const navigate = useNavigate()

    // * Active index for which user is chosen, and set correct index
    const [activeIndex, setActiveIndex] = useState(undefined)

    const handleChange = () => {
        if (possible.length === 0) return

        const profile = possible.users.filter(
            (user) =>
                (first && selected2 && user.username !== selected2.username) ||
                (!first && selected1 && user.username !== selected1.username) ||
                (first && !selected2) ||
                (!first && !selected1)
        )[activeIndex]
        if (first) setProfile1(profile)
        else setProfile2(profile)
        setActiveIndex(undefined)
        setOpen(false)
    }

    useEffect(() => {
        if (
            (selected1 === undefined && first) ||
            (selected2 === undefined && !first) ||
            possible.length === 0
        )
            return
        setActiveIndex(
            possible.users
                .filter(
                    (user) =>
                        (first &&
                            selected2 &&
                            user.username !== selected2.username) ||
                        (!first &&
                            selected1 &&
                            user.username !== selected1.username) ||
                        (first && !selected2) ||
                        (!first && !selected1)
                )
                .map((p) => p.username)
                .indexOf(first ? selected1.username : selected2.username)
        )
    }, [first, possible, selected1, selected2])

    // * Handle missing variables
    if (possible === undefined || possible.length === 0)
        return <CircularProgress />

    return (
        <>
            <Dialog open={open} fullWidth={true} maxWidth={'sm'} scroll="paper">
                <DialogTitle>
                    {first
                        ? 'First Profile to Compare'
                        : 'Second Profile to Compare'}
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ width: '100%' }}>
                        <Grid container spacing={1}>
                            {possible.users
                                .filter(
                                    (user) =>
                                        (first &&
                                            selected2 &&
                                            user.username !==
                                                selected2.username) ||
                                        (!first &&
                                            selected1 &&
                                            user.username !==
                                                selected1.username) ||
                                        (first && !selected2) ||
                                        (!first && !selected1)
                                )
                                .map((p, index) => (
                                    <UserPreload
                                        key={index}
                                        index={index}
                                        activeIndex={activeIndex}
                                        setActiveIndex={setActiveIndex}
                                        p={p}
                                        currentAPITime={currentAPITime}
                                    />
                                ))}
                        </Grid>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => navigate('/')}>Dashboard</Button>
                    {activeIndex !== undefined && (
                        <Button
                            onClick={handleChange}
                            color="success"
                            variant="outlined"
                        >
                            Select
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}
