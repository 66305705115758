import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { apiRequestPost } from '../../utils/api'
import { useCookies } from 'react-cookie'

export const VerifyMail = () => {
    const navigate = useNavigate()
    const [cookies] = useCookies(['jwt'])
    const [searchParams] = useSearchParams()

    useEffect(() => {
        async function verifyMail() {
            const token = searchParams.get('token')
            const email = searchParams.get('email')

            console.log(token, email)
            if (token !== null || email !== null) {
                await apiRequestPost('/verify-email', { token, email }, cookies)
                navigate('/')
            } else navigate('/')
        }
        verifyMail()
    }, [searchParams, cookies, navigate])

    return <div>Will transfer you to another page in a sec, stand by.</div>
}
