import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import Badge from '@mui/material/Badge'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { apiRequestPost } from '../utils/api.js'

export const AcceptPodcast = () => {
    document.title = 'AutoIMDb | Verify Podcasts'

    const [cookies, setCookies] = useCookies([
        'jwt',
        'hideChecked',
        'densePadding',
        'pageSize',
    ])
    if (!['true', 'false'].includes(cookies.hideChecked))
        setCookies('hideChecked', 'true', {
            path: '/',
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        })

    if (!['true', 'false'].includes(cookies.densePadding))
        setCookies('densePadding', 'true', {
            path: '/',
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        })

    if (![25, 50, 100].includes(Number(cookies.pageSize)))
        setCookies('pageSize', 25, {
            path: '/',
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        })

    // Pages
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(
        Number(cookies.pageSize) || 25
    )

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        const newValue = Number(event.target.value)
        setRowsPerPage(newValue)
        setCookies('pageSize', newValue, {
            path: '/',
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        })
        setPage(0)
    }

    // Function for opening Modal and set resubmit option to true
    const [record, setRecord] = useState({
        code: '',
        opinion: undefined,
    })
    async function clickedUpdate(code, existing, userAgrees) {
        let opinion = undefined
        if (!existing && (!userAgrees === true || userAgrees == null))
            opinion = true
        else if (existing && (!userAgrees === false || userAgrees == null))
            opinion = false

        setRecord({ code: code, opinion: opinion })
    }

    // New Podcast
    const [new_podcasts, setNewPodcasts] = useState([])
    const [amountOfUnratedPodcasts, setAmountOfUnratedPodcasts] = useState(0)

    const [hideResponded, setHideResponded] = useState(
        cookies.hideChecked === 'true'
    )

    // * Effect when pagesize, rows or hide responded changes
    useEffect(() => {
        const fetchNewPodcasts = async (skip, limit, rated, cookies) => {
            const newPodcastData = await apiRequestPost(
                '/get-rate-podcasts',
                { skip, limit, hideResponded: rated },
                cookies
            )

            // Add copies of fields
            try {
                for (var i = 0; i < newPodcastData.pods.length; i++) {
                    newPodcastData.pods[i].downvote = {
                        code: newPodcastData.pods[i].code,
                        userAgrees: newPodcastData.pods[i].userAgrees,
                        amount_votes: newPodcastData.pods[i].disagree.length,
                    }
                    newPodcastData.pods[i].upvote = {
                        code: newPodcastData.pods[i].code,
                        userAgrees: newPodcastData.pods[i].userAgrees,
                        amount_votes: newPodcastData.pods[i].agree.length,
                    }
                }
            } catch (e) {}

            setAmountOfUnratedPodcasts(newPodcastData.totalMatching)
            setNewPodcasts(newPodcastData.pods)
        }
        fetchNewPodcasts(
            page * rowsPerPage,
            rowsPerPage,
            hideResponded,
            cookies
        )
    }, [page, rowsPerPage, hideResponded, cookies])

    // * Effect when updates opinion on podcast
    useEffect(() => {
        const fetchNewPodcasts = async (skip, limit, rated, cookies) => {
            setRecord({
                code: '',
                opinion: undefined,
            })

            await apiRequestPost(
                '/verify-podcast',
                { code: record.code, agree: record.opinion },
                cookies
            )

            if (
                (new_podcasts.length - 1 >= limit && hideResponded) ||
                new_podcasts.length >= amountOfUnratedPodcasts
            ) {
                const updated_array = new_podcasts.filter(
                    (item) => item.code !== record.code
                )

                setAmountOfUnratedPodcasts(amountOfUnratedPodcasts - 1)
                setNewPodcasts(updated_array)
            } else {
                const newPodcastData = await apiRequestPost(
                    '/get-rate-podcasts',
                    { skip, limit, hideResponded: rated },
                    cookies
                )

                // Add copies of fields
                try {
                    for (var i = 0; i < newPodcastData.pods.length; i++) {
                        newPodcastData.pods[i].downvote = {
                            code: newPodcastData.pods[i].code,
                            userAgrees: newPodcastData.pods[i].userAgrees,
                            amount_votes:
                                newPodcastData.pods[i].disagree.length,
                        }
                        newPodcastData.pods[i].upvote = {
                            code: newPodcastData.pods[i].code,
                            userAgrees: newPodcastData.pods[i].userAgrees,
                            amount_votes: newPodcastData.pods[i].agree.length,
                        }
                    }
                } catch (e) {}

                setAmountOfUnratedPodcasts(newPodcastData.totalMatching)
                setNewPodcasts(newPodcastData.pods)
            }
        }

        if (record.code !== '')
            fetchNewPodcasts(
                page * rowsPerPage,
                rowsPerPage,
                hideResponded,
                cookies
            )
    }, [
        record,
        cookies,
        page,
        rowsPerPage,
        hideResponded,
        new_podcasts,
        amountOfUnratedPodcasts,
    ])

    // Dense and copied
    const [copied, setCopied] = useState('')
    const [dense, setDense] = useState(cookies.densePadding === 'true')
    const emptyRows = 0

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
        setCookies('densePadding', event.target.checked, {
            path: '/',
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        })
    }

    const handleChangeHide = (event) => {
        setHideResponded(event.target.checked)
        setCookies('hideChecked', event.target.checked, {
            path: '/',
            expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        })
    }

    // Column setup
    const columns = [
        {
            id: 'title',
            label: 'Title',
            format: (value) => (
                <span>
                    {value}
                    <CopyToClipboard
                        text={value}
                        onCopy={() => setCopied(value)}
                    >
                        <Tooltip title={copied === value ? 'Copied' : 'Copy'}>
                            <IconButton>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                </span>
            ),
            minWidth: 170,
        },
        {
            id: 'code',
            label: 'Spotify-ID',
            width: 75,
            format: (value) => (
                <a
                    href={'https://open.spotify.com/show/' + value}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Link
                </a>
            ),
            align: 'center',
        },
        {
            id: 'downvote',
            label: 'Exists',
            width: 50,
            format: (value) => (
                <Tooltip title="Exists">
                    <IconButton
                        id="existing_value"
                        onClick={() =>
                            clickedUpdate(value.code, true, value.userAgrees)
                        }
                    >
                        <Badge
                            badgeContent={value.amount_votes}
                            color="primary"
                        >
                            <ArrowCircleDownIcon
                                color={
                                    value.userAgrees === false ? 'error' : ''
                                }
                            />
                        </Badge>
                    </IconButton>
                </Tooltip>
            ),
            align: 'center',
        },
        {
            id: 'upvote',
            label: 'Verify',
            width: 50,
            format: (value) => (
                <Tooltip title="Verify">
                    <IconButton
                        id="resubmit_value"
                        onClick={() =>
                            clickedUpdate(value.code, false, value.userAgrees)
                        }
                    >
                        <Badge
                            badgeContent={value.amount_votes}
                            color="primary"
                        >
                            <ArrowCircleUpIcon
                                color={value.userAgrees === true ? 'error' : ''}
                            />
                        </Badge>
                    </IconButton>
                </Tooltip>
            ),
            align: 'center',
        },
    ]

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }} className="outer-page">
            <TableContainer sx={{ maxHeight: 700 }}>
                <Table
                    stickyHeader
                    aria-label="sticky table"
                    size={dense ? 'small' : 'medium'}
                >
                    <TableHead>
                        <TableRow
                            style={{
                                height: (dense ? 33 : 53) * emptyRows,
                            }}
                        >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, width: column.width }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {new_podcasts.slice(0, rowsPerPage).map((row) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id]
                                        return (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={
                                                    column.id === 'title'
                                                        ? row.imdb_searchmatch_exists
                                                            ? { color: 'red' }
                                                            : row.imdb_searchmatch_exists ===
                                                              null
                                                            ? {}
                                                            : { color: '#50b043' }
                                                        : {}
                                                }
                                            >
                                                {column.format
                                                    ? column.format(value)
                                                    : value}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={amountOfUnratedPodcasts}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <FormControlLabel
                control={
                    <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Dense Padding"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={hideResponded}
                        onChange={handleChangeHide}
                    />
                }
                label="Hide Checked"
            />
        </Paper>
    )
}
