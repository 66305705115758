import React from 'react'
import { Grid, Box } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChartRatingsAndAverageByType = ({ profile }) => {
    const types = []
    profile.ratings.ratings.forEach((item) => {
        if (!types.includes(item.type)) types.push(item.type)
    })

    const ratings = {}
    types.forEach((item) => (ratings[item] = 0))
    const average = {}
    types.forEach((item) => (average[item] = 0))
    profile.ratings.ratings.forEach((rating) => {
        ratings[rating.type]++
        average[rating.type] += rating.yourRating
    })
    Object.keys(ratings).forEach((key) => {
        average[key] /= ratings[key]
    })

    const order = Object.values(ratings).sort((a, b) => b - a)
    const newOrderTypes = []
    order.forEach((val) => {
        for (let j = 0; j < types.length; j++) {
            const type = types[j]
            if (val === ratings[type]) newOrderTypes.push(type)
        }
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
                className="m-auto"
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Ratings and Average by Type',
                            },
                        },
                        scales: {
                            A: {
                                id: 'A',
                                type: 'linear',
                                position: 'left',
                            },
                            B: {
                                type: 'linear',
                                position: 'right',
                                min: 1,
                                max: 10,
                            },
                        },
                    }}
                    data={{
                        labels: newOrderTypes.map((item) => {
                            if (!item.endsWith('s')) item += 's'

                            return `${item
                                .charAt(0)
                                .toUpperCase()}${item.substring(1)}`
                        }),
                        datasets: [
                            {
                                label: 'Ratings',
                                data: newOrderTypes.map(
                                    (type) => ratings[type]
                                ),
                                yAxisID: 'A',
                                backgroundColor: '#16CC62',
                            },
                            {
                                label: 'Average',
                                data: newOrderTypes.map(
                                    (type) => average[type]
                                ),
                                yAxisID: 'B',
                                backgroundColor: '#196EE6',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}
export default BarChartRatingsAndAverageByType
