import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const RatingsByDistribution = ({
    profile1,
    profile2,
    profile1RatingsByScore,
    profile2RatingsByScore,
}) => {
    if (
        profile1RatingsByScore === undefined &&
        profile2RatingsByScore === undefined
    )
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        scales: {
                            A: {
                                id: 'A',
                                type: 'linear',
                                position: 'left',
                            },
                            B: {
                                type: 'linear',
                                position: 'right',
                            },
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: 'Ratings by Distribution',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')}`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                            (score) => `Rating ${score}`
                        ),
                        datasets: [
                            {
                                label: profile1.username,
                                yAxisID: 'A',
                                data: Object.values(profile1RatingsByScore),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: profile2.username,
                                yAxisID: 'B',
                                data: Object.values(profile2RatingsByScore),
                                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default RatingsByDistribution
