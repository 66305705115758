import React, { useState, useEffect } from 'react'
import {
    Card,
    Box,
    CardMedia,
    Typography,
    CardContent,
    Grid,
    Snackbar,
    AlertTitle,
    Alert,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
} from '@mui/material'
import { apiRequestGet } from '../utils/api'
import { SearchInput } from '../components/UsernameSearch/InputSearch'
import { useCookies } from 'react-cookie'
import { GradientCircularProgress } from '../components/UsernameSearch/GradientCircularProgress'

export const UsernameSearch = () => {
    document.title = 'AutoIMDb | Username Search'

    const [searchWord, setSearchWord] = useState("")
    const [results, setResults] = useState([])
    const [totalUsers, setTotalUsers] = useState(0)
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(10)
    const [caseSensitive, setCaseSensitive] = useState(true)
    const [startsWith, setStartsWith] = useState(false)
    const [hasPicture, setHasPicture] = useState(false)
    const [idSort, setIdSort] = useState(1)

    const [cookies, setCookies] = useCookies(['limit', 'caseSensitive', 'startsWith', 'idSort', 'hasPicture'])

    // * Alert
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState(false)
    const [alertContent, setAlertContent] = useState("")
    const handleCloseAlertSnackbar = () => {
        setAlertSnackbarOpen(false)
    }

    useEffect(() => {
        async function fetchAmount() {
            try {
                const res = await apiRequestGet(`/amount-of-usernames`)
                if (res.error) {
                    setAlertContent("An unknown error has occured when fetching amount of usernames")
                    setAlertSnackbarOpen(true)
                }
                else setTotalUsers(res.totalUsernames)
            } catch (_) { }
        }
        fetchAmount()
    }, [])

    useEffect(() => {
        if (cookies.limit !== undefined)
            setLimit(cookies.limit)
    }, [cookies.limit])
    useEffect(() => {
        if (cookies.caseSensitive !== undefined)
            setCaseSensitive(cookies.caseSensitive === 'true')
    }, [cookies.caseSensitive])
    useEffect(() => {
        if (cookies.startsWith !== undefined)
            setStartsWith(cookies.startsWith === 'true')
    }, [cookies.startsWith])
    useEffect(() => {
        if (cookies.idSort !== undefined)
            setIdSort(cookies.idSort)
    }, [cookies.idSort])
    useEffect(() => {
        if (cookies.hasPicture !== undefined)
            setHasPicture(cookies.hasPicture === 'true')
    }, [cookies.hasPicture])

    const search = async () => {
        if (searchWord.trim() === "") {
            setAlertContent("Search field empty")
            setAlertSnackbarOpen(true)
            return
        }
        setLoading(true)
        try {
            const res = await apiRequestGet(`/search-user?username=${searchWord}&limit=${limit}&caseSensitive=${caseSensitive}&startsWith=${startsWith}&sortIdBy=${idSort}&hasPicture=${hasPicture}`)
            if (res.matches) {
                setResults(res.matches)
            }
        } catch (_) { }
        setLoading(false)
    }

    const [searchButtonHovered, setSearchButtonHovered] = useState(false)

    return (
        <div style={{ display: 'inline-block', width: '100%', minHeight: 'calc(100vh - 64px)' }}>
            <div style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                backgroundRepeat: 'repeat-y',
                color: '#8d9399',
                padding: '10px 0px',
                boxSizing: 'border-box',
                overflowWrap: 'anywhere',
                width: '100%',
                height: '100%',
            }}>
                <div className="searchbar-settings">
                    <div className="searchbar-outline">
                        <SearchInput searchWord={searchWord} setSearchWord={setSearchWord} search={search} placeholder={`Search ${totalUsers === 0 ? 'N/A' : totalUsers.toLocaleString('en-US')} Usernames`} />
                        <span
                            style={{
                                height: 'calc(100% - 26px)',
                                backgroundColor: searchButtonHovered ? '#545759' : '#676c70',
                                right: 0,
                                position: 'absolute',
                                top: '13px',
                                width: '60px',
                                borderRadius: '0 35px 35px 0',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={(e) => { setSearchButtonHovered(true) }}
                            onMouseLeave={(e) => { setSearchButtonHovered(false) }}
                            onClick={search}
                        />
                        <span
                            style={{
                                backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAARdJREFUOBHNkz1uwkAQhTGkhCuELlQUiAtwilzHtU8BVLkHF4iokbJJkS41EiTS5nvL2FrQGMsdIz3N7ntvZn+8HgyyiDEuwBv4BifLmi8yW/sQ4xMowRl4Ib4Eo/YuKGYixT9QgSUYW9ZcvKJsbYSo42hFmVeeUbzp8vnHRNAdKCqvSc1JTy78NXeVEXWxiuWVcDORnlz4b6TLFFFfRzF2DUaiT5IrxqPnG0L+mDDzDBn3YuNDxjVDNdrZ7LVh/UGtv7sy2+3z1X7xz91GIhFLoOh6R+vWJtao62VrgW1aKsZP8rSrYf6vHSnYgw1IxyEHoAjgfrN7K6kYBKAI4DGbfbCzZ72j3lEUxRdFKxB6F3sF2okg7R/6f6gee6OuegAAAABJRU5ErkJggg==")',
                                right: '10px',
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                width: '50px',
                                height: '50px',
                                backgroundPosition: '50% 50%',
                                color: '#8d9399',
                                opacity: 1,
                                position: 'absolute',
                                backgroundRepeat: 'no-repeat',
                                transform: 'scale(1)',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={(e) => { setSearchButtonHovered(true) }}
                            onMouseLeave={(e) => { setSearchButtonHovered(false) }}
                            onClick={search}
                        />

                        <span
                            className="searchbar-start-icon"
                            style={{
                                backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAARdJREFUOBHNkz1uwkAQhTGkhCuELlQUiAtwilzHtU8BVLkHF4iokbJJkS41EiTS5nvL2FrQGMsdIz3N7ntvZn+8HgyyiDEuwBv4BifLmi8yW/sQ4xMowRl4Ib4Eo/YuKGYixT9QgSUYW9ZcvKJsbYSo42hFmVeeUbzp8vnHRNAdKCqvSc1JTy78NXeVEXWxiuWVcDORnlz4b6TLFFFfRzF2DUaiT5IrxqPnG0L+mDDzDBn3YuNDxjVDNdrZ7LVh/UGtv7sy2+3z1X7xz91GIhFLoOh6R+vWJtao62VrgW1aKsZP8rSrYf6vHSnYgw1IxyEHoAjgfrN7K6kYBKAI4DGbfbCzZ72j3lEUxRdFKxB6F3sF2okg7R/6f6gee6OuegAAAABJRU5ErkJggg==")',
                                backgroundColor: 'rgba(0,0,0,0)',
                                width: '45px',
                                height: '45px',
                                backgroundPosition: '50% 50%',
                                color: '#8d9399',
                                opacity: .4,
                                position: 'absolute',
                                backgroundRepeat: 'no-repeat',
                                transform: 'scale(1)',
                            }}
                        />
                    </div>
                    <FormGroup style={{
                        maxWidth: '880px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%',
                        alignItems: 'flex-start',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                    }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={caseSensitive}
                                    onChange={(event) => setCookies("caseSensitive", !caseSensitive)}
                                />
                            }
                            label="Case Sensitive"
                            style={{ maxWidth: 'fit-content' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={startsWith}
                                    onChange={(event) => setCookies("startsWith", !startsWith)}
                                />
                            }
                            label="Starts With"
                            style={{ maxWidth: 'fit-content' }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hasPicture}
                                    onChange={(event) => setCookies("hasPicture", !hasPicture)}
                                />
                            }
                            label="Has Picture"
                            style={{ maxWidth: 'fit-content' }}
                        />
                        <FormControl size="small">
                            <InputLabel id="limit-label">Limit</InputLabel>
                            <Select
                                value={limit}
                                label="Limit"
                                labelId="limit-label"
                                onChange={(event) => setCookies("limit", event.target.value)}
                                style={{ color: 'white', width: '5rem', marginRight: '10px' }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl size="small">
                            <InputLabel id="id-sort-label">ID Sort</InputLabel>
                            <Select
                                value={idSort}
                                label="ID Sort"
                                labelId="id-sort-label"
                                onChange={(event) => setCookies("idSort", event.target.value)}
                                style={{ color: 'white', width: '9rem', marginRight: '10px' }}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </div>

                {
                    loading ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <GradientCircularProgress size="15rem" />
                        </div>
                        :
                        <Grid container spacing={1} style={{
                            justifyContent: 'center',
                            marginBottom: '20px',
                        }}>
                            {results.map(user => {
                                return (
                                    <Grid item key={user.id}>
                                        <Card sx={{
                                            display: 'flex',
                                            borderRadius: '1rem',
                                            backgroundColor: 'rgba(21, 31, 47, 0.7)',
                                            maxWidth: 'fit-content',
                                            padding: '0.5rem',
                                            border: '1px solid gray',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.8s, transform 0.8s',
                                            position: 'relative',
                                            '&:hover': {
                                                backgroundColor: 'rgba(21, 31, 47, 1)',
                                                transform: 'scale(1.05)',
                                            },
                                        }}>
                                            <div style={{
                                                position: 'absolute', bottom: '5px', left: '24px', display: 'inline-block'
                                            }}>
                                                <Chip
                                                    label={`#${parseInt(user.id.substr(2)).toLocaleString('en-US')}`}
                                                    title="Registration"
                                                    color="success"
                                                    size="small"
                                                    style={{ marginRight: '5px' }}
                                                />
                                                {user.contributorPlacement !== 0 && <Chip
                                                    label={`#${user.contributorPlacement.toLocaleString('en-US')}`}
                                                    title="Contributor"
                                                    color="primary"
                                                    size="small"
                                                />}
                                            </div>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <CardContent sx={{ flex: '1 0 auto' }}>
                                                    <Typography component="div" variant="h5" style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '12rem',
                                                        color: 'white',
                                                    }}>
                                                        {user.username}
                                                    </Typography>
                                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                                        {user.id}
                                                    </Typography>
                                                </CardContent>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: 75, height: 75, borderRadius: '5rem', marginTop: 'auto', marginBottom: 'auto' }}
                                                image={
                                                    `${user.profilePicture !== "" ?
                                                        user.profilePicture :
                                                        "https://images-na.ssl-images-amazon.com/images/M/MV5BMjQ4MTY5NzU2M15BMl5BanBnXkFtZTgwNDc5NTgwMTI@._V1_SY100_SX100_.jpg"}`
                                                }
                                                alt={`${user.username}'s Profile Picture`}
                                            />
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                }
            </div>
            <Snackbar
                open={alertSnackbarOpen}
                onClose={handleCloseAlertSnackbar}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    severity="warning"
                    variant="filled"
                    onClose={handleCloseAlertSnackbar}
                >
                    <AlertTitle>Error</AlertTitle>
                    {alertContent}
                </Alert>
            </Snackbar>
        </div>
    )
}