import React, { useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { Grid, Typography, Fab } from '@mui/material'
import { useCookies } from 'react-cookie'

import BarChartRatingsByDistribution from '../Charts/BarChartRatingsByDistribution'
import BarChartRatingsByYear from '../Charts/BarChartRatingsByYear'
import BarChartRatingsByGenre from '../Charts/BarChartRatingsByGenre'
import BarChartGenreAverageRating from '../Charts/BarChartGenreAverageRating'
import StackedBarChartMainstream from '../Charts/StackedBarChartMainstream'
import HoursWastedWatchingMovies from '../HoursWastedWatchingMovies'
import StackedBarChartAverage from '../Charts/StackedBarChartAverage'
import PieChartOriginofCountry from '../Charts/PieChartOriginofCountry'
import BarChartRatingsMadeByYear from '../Charts/BarChartRatingsMadeByYear'
import BarChartRatingsMadeByMonth from '../Charts/BarChartRatingsMadeByMonth'
import BarChartRatingsAndAverageByType from '../Charts/BarChartRatingsAndAverageByType'
import BarChartRatingsTypeByYear from '../Charts/BarChartRatingsTypeByYear'

import LineChartContributionPlacement from '../Charts/Contributions/LineChartContributionPlacement'
import LineChartContributions from '../Charts/Contributions/LineChartContributions'
import UserCard from '../Charts/Contributions/UserCard'

import Achievements from '../Achivements'

const Viewer = ({ profile, open, setOpen }) => {
    const [cookies, setCookies] = useCookies([
        'hideAcomplished',
        'hideUnavailable',
    ])
    const [hideAcomplished, setHideAcomplished] = useState(false)
    const [hideUnavailable, setHideUnavailable] = useState(true)

    const [ran, setRan] = useState(false)
    useEffect(() => {
        if (!ran) {
            if (cookies.hideAcomplished !== undefined)
                setHideAcomplished(cookies.hideAcomplished === 'true')
            else setHideAcomplished(false)
            if (cookies.hideUnavailable !== undefined)
                setHideUnavailable(cookies.hideUnavailable === 'true')
            else setHideUnavailable(true)
            setRan(true)
        }
    }, [ran, cookies.hideAcomplished, cookies.hideUnavailable])

    if (!profile || Object.keys(profile).length === 0) return <></>

    return (
        <>
            <Grid style={{ width: '100%', position: 'relative' }}>

                {/* Stat Charts */}
                <Grid container columns={2} spacing={1}>
                    {/* Hours Wasted & Info */}
                    <HoursWastedWatchingMovies profile={profile} />

                    {/* Ratings by Distribution */}
                    <BarChartRatingsByDistribution profile={profile} />

                    {/* Ratings and Average by Type */}
                    <BarChartRatingsAndAverageByType profile={profile} />

                    {/* Rating Type by Year */}
                    <BarChartRatingsTypeByYear profile={profile} />

                    {/* Ratings by Year */}
                    <BarChartRatingsByYear profile={profile} />

                    {/* Ratings by Genre */}
                    <BarChartRatingsByGenre profile={profile} />

                    {/* Genre by Rating */}
                    <BarChartGenreAverageRating profile={profile} />

                    {/* Amount of Movies by Country of Origin */}
                    <PieChartOriginofCountry profile={profile} />

                    {/* Amount of Movies Mainstream */}
                    <StackedBarChartMainstream profile={profile} />

                    {/* Distribution of below average, on average or above average */}
                    <StackedBarChartAverage profile={profile} />

                    {/* Distributions of date (year) when contributions were made */}
                    <BarChartRatingsMadeByYear profile={profile} />

                    {/* Distributions of date (month) when contributions were made */}
                    <BarChartRatingsMadeByMonth profile={profile} />

                    {/* Contributor */}
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Typography
                            variant="h2"
                            className="headline-achievements"
                        >
                            IMDb - Contributor
                        </Typography>
                    </Grid>
                    {/* Total Contribution Position Since 2021 */}
                    <LineChartContributionPlacement profile={profile} />

                    {/* Total Contributions By Time */}
                    <LineChartContributions profile={profile} />

                    {/* Tailing User */}
                    <UserCard profile={profile} headline={'Previous'} />

                    {/* Next User */}
                    <UserCard profile={profile} headline={'Next'} />

                    {/* Achievements and progress */}
                    <Achievements profile={profile} hideAcomplished={hideAcomplished} setHideAcomplished={setHideAcomplished} setCookies={setCookies} hideUnavailable={hideUnavailable} setHideUnavailable={setHideUnavailable} />
                </Grid>
            </Grid>
            {/* Stats - Latest rated, first rated, account age */}

            {!open && (
                <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    style={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                    }}
                    onClick={() => {
                        setOpen(true)
                    }}
                >
                    <EditIcon sx={{ mr: 1 }} />
                    Change User
                </Fab>
            )}
        </>
    )
}

export default Viewer
