import React from 'react'
import { Line } from 'react-chartjs-2'
import { Grid, Box } from '@mui/material'

const EpisodeLineChart = ({ coverage_data }) => {
    let labels = []
    let datasets = []
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    if (
        coverage_data !== undefined &&
        Object.keys(coverage_data).includes('coverage')
    ) {
        let total_episodes = []
        let uploaded_episodes = []
        coverage_data['coverage'].forEach((document) => {
            const documentDate = new Date(document.date)
            labels.push(
                documentDate.getDate() +
                    '. ' +
                    months[documentDate.getMonth()] +
                    ' ' +
                    documentDate.getFullYear()
            )
            total_episodes.push(document.total_episodes)
            uploaded_episodes.push(
                document.total_episodes - document.remaining_episodes
            )
        })
        datasets.push({
            label: 'Total Episodes',
            data: total_episodes,
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.1)',
        })
        datasets.push({
            label: 'Uploaded Episodes',
            data: uploaded_episodes,
            borderColor: 'rgba(51, 255, 51, 1)',
            backgroundColor: 'rgba(51, 255, 51, 0.1)',
        })
    }

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: '100%',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Line
                    options={{
                        responsive: true,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                        min: 0,
                                    },
                                },
                            ],
                        },
                    }}
                    data={{
                        labels: labels,
                        datasets: datasets,
                    }}
                />
            </Box>
        </Grid>
    )
}

export default EpisodeLineChart
