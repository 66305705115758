import React from 'react'
import { Card, CardText, CardBody, CardTitle } from 'reactstrap'
import CircularProgress from '@mui/material/CircularProgress'
import Tooltip from '@mui/material/Tooltip'
import { Grow, IconButton } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { Avatar } from '@mui/material'

export const StatCard = ({
    type,
    value,
    icon,
    color,
    extra_field,
    extra_field_color,
    info,
}) => {
    function percentageRender(x) {
        if (x != null)
            return (
                <CardText
                    className="statCardExtraField"
                    style={{ color: extra_field_color }}
                >
                    {x}%
                </CardText>
            )
        return (
            <CardText className="statCardExtraField transparent">
                Hidden
            </CardText>
        )
    }

    return (
        <Card
            className={color}
            style={{
                whiteSpace: 'nowrap',
                borderRadius: '0.5rem',
                padding: '0.4rem',
                boxShadow: '0 0 0 transparent',
                borderWidth: '1px',
                border: '1px solid rgba(32, 39, 140, .125)',
                position: 'relative',
            }}
        >
            <Avatar
                alt={`${type} Icon`}
                sx={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    bgcolor: 'hsla(0, 0%, 100%, .1)',
                    height: 56,
                    width: 56,
                }}
            >
                {icon}
            </Avatar>
            <CardBody
                style={{
                    textAlign: 'center',
                }}
            >
                <CardText
                    className="statCardValue"
                    style={{
                        fontWeight: 700,
                        fontSize: '2rem',
                        lineHeight: 1.375,
                    }}
                >
                    {value !== 'undefined' && value !== undefined ? (
                        value
                    ) : (
                        <CircularProgress color="success" />
                    )}
                </CardText>
                <CardTitle
                    className="statCardType"
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        fontSize: '0.75rem',
                        fontWeight: 600,
                        letterSpacing: '0.5px',
                        lineHeight: 2.5,
                        textTransform: 'uppercase',
                    }}
                >
                    {type}
                </CardTitle>
                {percentageRender(extra_field)}
                {info ? (
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '0.1rem',
                            right: '0.1rem',
                        }}
                    >
                        <Tooltip
                            TransitionComponent={Grow}
                            TransitionProps={{ timeout: 250 }}
                            title={info}
                            arrow
                            placement="bottom"
                        >
                            <InfoIcon sx={{ color: 'white' }} />
                        </Tooltip>
                    </IconButton>
                ) : null}
            </CardBody>
        </Card>
    )
}
