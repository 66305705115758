import React, { useEffect, useState } from 'react';
import { Button, Autocomplete, TextField, Container } from '@mui/material';
import Alert from '@mui/material/Alert';
import { apiRequestPost } from '../utils/api.js';
import { useCookies } from 'react-cookie';
import countriesjson from '../resources/json/countries.json';
import languagesjson from '../resources/json/languages.json'

export const NewPodcast = () => {

    document.title = 'AutoIMDb | New Podcast';

    const [cookies] = useCookies(['jwt']);

    const [podcast, setPodcast] = useState({
        title: "",
        code: "",
        imdb_id: "",
        country: "",
        language: "",

        title_edited: false,
        code_edited: false,
        country_edited: false,
        language_edited: false
    });

    function updateForm(value, event) {
        return setPodcast((prev) => {
            return { ...prev, ...value };
        });
    }

    const [error, setError] = useState({
        error: "",
        color: "green"
    });

    async function submitPodcast(e) {
        e.preventDefault();

        const newPodcast = { ...podcast };

        const res = await apiRequestPost('/new-podcast', newPodcast, cookies);

        console.log(res);
        if (res.error === undefined) {
            setError({ error: `Successfully added: ${res.title}`, color: "green" });
            updateForm({ title: "", code: "", imdb_id: "", country: "", language: "", title_edited: "", code_edited: "", country_edited: "", language_edited: "" });
        } else {
            setError({ error: res.error, color: "red" });
        }
    }

    // Podcasts query (simple)
    const [podcasts_simple, setPodcastsSimple] = useState([]);

    useEffect(() => {
        async function getSimplePodcasts(title, code, imdb_id) {
            const similarTitle = await apiRequestPost('/compare-podcast', { title, code, imdb_id }, cookies);
            setPodcastsSimple(similarTitle);
        }
        if (podcast.title || podcast.code || podcast.imdb_id)
            getSimplePodcasts(podcast.title, podcast.code, podcast.imdb_id);
    }, [podcast, cookies])

    // List countries and languages
    const countries = countriesjson.countries;
    const languages = languagesjson.languages;

    const [inputCountry, setInputCountry] = useState('');
    const [inputLanguage, setInputLanguage] = useState('');
    return (
        <form
            onSubmit={submitPodcast}
            autoComplete="off"
            style={{
                margin: 'auto',
                border: '1px solid black',
                borderRadius: '1rem',
            }}
            className="outer-page"
        >
            <Container maxWidth="sm" className='form spacingBottom'>
                <h2 className='center'>New Podcast</h2>

                {
                    error.error ? (
                        <Alert variant="filled" severity={error.color === 'green' ? 'success' : 'error'}>
                            {error.error}
                        </Alert>
                    ) : null
                }

                <TextField
                    id="title"
                    error={(podcast.title || !podcast.title_edited ? false : true) || (podcasts_simple.title && podcast.title_edited ? true : false)}
                    helperText={(podcast.title || !podcast.title_edited ? '' : 'Required') || (podcasts_simple.title && podcast.title_edited ? `Possible duplicate: ${podcasts_simple.title_info}` : '')}
                    label="Title"
                    variant="outlined"
                    className='block m-tb'
                    sx={{ width: '300px' }}
                    value={podcast.title}
                    onChange={(e) => updateForm({ title: e.target.value, title_edited: true }, e)}
                />

                <TextField
                    id="code"
                    error={(podcast.code.length === 22 || !podcast.code_edited ? false : true) || (podcasts_simple.code && podcast.code_edited ? true : false)}
                    helperText={(podcast.code.length === 22 || !podcast.code_edited ? '' : 'Required: Length 22') || (podcasts_simple.code && podcast.code_edited ? 'Duplicate' : '')}
                    label="Spotify podcast ID"
                    variant="outlined"
                    value={podcast.code}
                    sx={{ width: '300px' }}
                    onChange={(e) => updateForm({ code: e.target.value, code_edited: true }, e)}
                    className='block m-tb'
                />

                <TextField
                    id="imdb_id"
                    error={((podcast.imdb_id.length > 0 && podcast.imdb_id.length !== 10) || (podcast.imdb_id.length > 0 && !podcast.imdb_id.startsWith('tt')) ? true : false) || (podcasts_simple.imdb_id && podcast.imdb_id !== '' ? true : false)}
                    helperText={((podcast.imdb_id.length > 0 && podcast.imdb_id.length !== 10) || (podcast.imdb_id.length > 0 && !podcast.imdb_id.startsWith('tt')) ? 'Length 0 or 10, starts with tt' : '') || (podcasts_simple.imdb_id && podcast.imdb_id !== '' ? 'Duplicate' : '')}
                    label="IMDb ID"
                    variant="outlined"
                    value={podcast.imdb_id}
                    sx={{ width: '300px' }}
                    onChange={(e) => updateForm({ imdb_id: e.target.value, imdb_id_edited: true }, e)}
                    className='block m-tb'
                />

                <Autocomplete
                    disablePortal
                    id="country"
                    options={countries}
                    value={podcast.country}
                    onChange={(e, newValue) => {
                        updateForm({ country: newValue, country_edited: true }, e);
                    }}
                    inputValue={inputCountry}
                    onInputChange={(event, newInputValue) => {
                        setInputCountry(newInputValue);
                    }}
                    sx={{ width: '300px' }}
                    className='block m-tb'
                    renderInput={(params) => <TextField {...params} label="Country" />}
                />

                <Autocomplete
                    disablePortal
                    id="language"
                    options={languages}
                    value={podcast.language}
                    onChange={(e, newValue) => {
                        updateForm({ language: newValue, language_edited: true }, e);
                    }}
                    inputValue={inputLanguage}
                    onInputChange={(event, newInputValue) => {
                        setInputLanguage(newInputValue);
                    }}
                    sx={{ width: '300px' }}
                    className='block m-tb'
                    renderInput={(params) => <TextField {...params} label="Language" />}
                />

                <Button variant="outlined" type="submit" className='center block'>Create</Button>
            </Container>
        </form>
    )
}