import React from 'react'
import AchievementCard from './Cards/AchievementCard'
import { Grid, Typography, Switch, FormControlLabel } from '@mui/material'
import './Styles/Achievements.css'

// * If the badges/achievements are updated use https://www.imgonline.com.ua/eng/cut-photo-into-pieces.php with 5 width to cut the image into new pieces.
// * Might require rework of rows and columns in the json file.

const Achievements = ({ profile, hideAcomplished, setHideAcomplished, setCookies, hideUnavailable, setHideUnavailable }) => {
    return (
        <Grid
            className="achievement-grid-outer"
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
        >
            {/* Achievement Settings */}
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormControlLabel
                    value="top"
                    control={
                        <Switch
                            color="primary"
                            checked={hideAcomplished}
                            onClick={() => {
                                setHideAcomplished(!hideAcomplished)
                                setCookies('hideAcomplished', !hideAcomplished)
                            }}
                        />
                    }
                    label="Hide Acomplished"
                    labelPlacement="start"
                />
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormControlLabel
                    value="top"
                    control={
                        <Switch
                            color="primary"
                            checked={hideUnavailable}
                            onClick={() => {
                                setHideUnavailable(!hideUnavailable)
                                setCookies('hideUnavailable', !hideUnavailable)
                            }}
                        />
                    }
                    label="Hide Unobtainable"
                    labelPlacement="start"
                />
            </Grid>

            {/* Load all achievement cards */}
            {Object.keys(profile.achievements.achievements).map((headline) => {
                const achievements = profile.achievements.achievements[headline]
                return (
                    <Grid container columns={12} spacing={1} key={headline}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography
                                variant="h2"
                                className="headline-achievements"
                            >
                                {headline}{' '}
                                {/* ({achievements.map(achievement => achievement.total_achieved_subbadges).reduce((a, b) => a + b, 0)} / {achievements.map(achievement => achievement.total_subbadges).reduce((a, b) => a + b, 0)}) */}
                            </Typography>
                        </Grid>
                        {achievements
                            .filter((achievement) =>
                                hideAcomplished
                                    ? achievement.current_milestone !==
                                    achievement.next_milestone
                                    : true
                            )
                            .filter((achievement) =>
                                hideUnavailable &&
                                    headline === 'Top Contributor'
                                    ? achievement.current_milestone ===
                                    achievement.next_milestone ||
                                    isNaN(achievement.title)
                                    : true
                            )
                            .filter((achievement) =>
                                hideUnavailable &&
                                    achievement.title === 'Beta Tester'
                                    ? achievement.current_milestone ===
                                    achievement.next_milestone
                                    : true
                            )
                            .map((achievement) => {
                                return (
                                    <AchievementCard
                                        key={`${achievement.title}-${headline}`}
                                        image_id={achievement.image_id}
                                        badge_name={achievement.title}
                                        current_milestone={
                                            achievement.current_milestone
                                        }
                                        next_milestone={
                                            achievement.next_milestone
                                        }
                                        total_achieved_subbadges={
                                            achievement.total_achieved_subbadges
                                        }
                                        total_subbadges={
                                            achievement.total_subbadges
                                        }
                                        is_next_badge={
                                            achievement.is_next_badge
                                        }
                                        badge_headline={headline}
                                    />
                                )
                            })}
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default Achievements
