import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import HelpIcon from '@mui/icons-material/Help'
import { Grid, Typography, Avatar, Card, CardContent } from '@mui/material'

export const StatusCard = ({ title, undertitle, running, id }) => {
    const colors = [
        'purple',
        'orchid',
        'specialgreen',
        'orange',
        'red',
        'dark',
        'darkblue',
        'blue',
        'yellow',
        'green',
    ]
    return (
        <Card
            sx={{ height: '100%', minWidth: '250px' }}
            className={colors.at(id)}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Grid item>
                        <Typography
                            gutterBottom
                            variant="overline"
                            sx={{ color: '#c7c5c5' }}
                        >
                            {undertitle}
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                            sx={{ color: 'white' }}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: running
                                    ? 'success.main'
                                    : running === false
                                    ? 'error.main'
                                    : '#FFB020',
                                height: 56,
                                width: 56,
                            }}
                        >
                            {running ? (
                                <CheckCircleIcon />
                            ) : running === false ? (
                                <CancelIcon />
                            ) : (
                                <HelpIcon />
                            )}
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
