import React from 'react'
import { Grid } from '@mui/material'
import { StatusCard } from '../components/Cards/StatusCard'

export const Status = ({ statuses }) => {
    document.title = 'AutoIMDb | Status'

    return (
        <Grid container spacing={1} className="outer-page p-2">
            {Object.keys(statuses).map((title, index) => {
                const data = statuses[title]
                return (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={title}>
                        <StatusCard
                            title={title}
                            undertitle={data.undertitle}
                            running={data.running}
                            id={index}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}
