import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { Line } from 'react-chartjs-2'

export const UsersBoard = ({ user_data }) => {
    document.title = 'AutoIMDb | Top 10 Contributors'

    if (user_data === undefined || !Object.keys(user_data).includes('data') || user_data.length === 0)
        return (
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: '100%',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <CircularProgress />
            </Box>
        )

    let labels = []
    let datasets = []
    const colors = [
        'rgba(255, 99, 132, 1)',
        'rgba(51, 255, 51, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(255, 0, 127, 1)',
        'rgba(204, 102, 0, 1)',
        'rgba(2, 8, 189, 1)',
        'rgba(190, 7, 222, 1)',
        'rgba(240, 0, 8, 1)',
        'rgba(99, 0, 230, 1)',
    ]
    if (user_data !== undefined && Object.keys(user_data).includes('data')) {
        let usernames = {}
        user_data['data'].forEach((document) => {
            if (!Object.keys(usernames).includes(document.username))
                usernames[document.username] = []
            if (!labels.includes(document.date)) labels.push(document.date)
        })

        user_data['data'].forEach((document) => {
            usernames[document.username].push(document.total)
        })

        for (var key in Object.keys(usernames)) {
            datasets.push({
                label: Object.keys(usernames)[key],
                data: usernames[Object.keys(usernames)[key]],
                borderColor: colors[key],
                cubicInterpolationMode: 'monotone',
            })
        }
    }

    const options = {
        responsive: true,
        scales: {
            y: {
                callback(value) {
                    return Number(value).toLocaleString('en')
                },
            },
        },
    }

    return (
        <Box
            sx={{
                backgroundColor: 'rgb(33, 49, 60)',
                padding: '1rem',
                width: '100%',
                height: '100%',
                borderRadius: '0.5rem',
            }}
            className="outer-page"
        >
            <Line
                options={options}
                data={{
                    labels: labels,
                    datasets: datasets,
                }}
            />
        </Box>
    )
}
