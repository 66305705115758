import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@mui/material'
import { secondsToFormatExtremelySimple } from '../utils/time'
import { socket } from '../utils/api'

const sorting = (a, b) => {
    if (a.running && !b.running)
        return -1
    else if (!a.running && b.running)
        return 1
    else {
        if (a.enabled && !b.enabled)
            return -1
        else if (!a.enabled && b.enabled)
            return 1

        if (a.running) return b.timeAgo - a.timeAgo
        else return a.nextRun - b.nextRun
    }
}

const ran = (job) => {
    if (!job.enabled) return <p><b className="text-slate-400">Disabled</b></p>

    if (job.running) return <p><b className="text-slate-400">Running</b></p>
    else return <p><b className="text-slate-400">Ran:</b> {job.timeAgo < 3000 ? "NOW" : secondsToFormatExtremelySimple(job.timeAgo / 1000)}</p>
}

const next = (job) => {
    if (!job.enabled) return <p><b className="text-slate-400">Disabled</b></p>

    if (job.running) return <p><b className="text-slate-400">Elapsed: </b> {secondsToFormatExtremelySimple(job.timeAgo / 1000)}</p>
    else return <p><b className="text-slate-400">Next:</b> {job.nextRun === 0 ? "NOW" : secondsToFormatExtremelySimple(job.nextRun / 1000)}</p>
}

const TriggerFire = () => {
    document.title = 'AutoIMDb | Trigger Fire'

    const [jobs, setJobs] = useState([])
    const [open, setOpen] = useState(false)
    const [selectedJob, setSelectedJob] = useState(undefined)

    // * Add seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setJobs((prevJobs) =>
                prevJobs
                    .map((job) => ({
                        ...job,
                        timeAgo: job.timeAgo + 1000,
                        nextRun: Math.max(job.nextRun - 1000, 0),
                    }))
                    .sort((a, b) => { return sorting(a, b) })
            )
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        socket.on('modules', (data) => {
            if (data === null) return
            const parsedData = data.sort((a, b) => { return sorting(a, b) })

            setJobs(parsedData)
        })
        socket.emit('getModules', {})

        return () => {
            socket.off('modules')
        }
    }, [])

    return (
        <div className="w-full inline-block box-border" style={{ minHeight: 'calc(100vh - 64px)' }}>
            <div className="w-full h-full bg-repeat-y" style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                color: '#8d9399',
                padding: '10px 0px',
                overflowWrap: 'anywhere',
            }}>
                <Grid container spacing={2} className="outer-page !p-4">
                    {
                        jobs.map((job, index) => {
                            return (
                                <Grid
                                    item
                                    className="relative"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    key={index}
                                >
                                    <div className={`${job.enabled ? "bg-blue-600" : "bg-gray-600"} rounded-md !m-1 !p-4`}>
                                        <b className="white text-lg">{job.title}</b>
                                        {ran(job)}
                                        {next(job)}
                                        {job.enabled && <Button
                                            variant={job.running ? "outlined" : "contained"}
                                            color={job.running ? "success" : "error"}
                                            sx={{
                                                position: 'absolute',
                                                right: '1rem',
                                                transform: 'translateY(-50%)',
                                                top: '50%',
                                                cursor: (job.running) ? 'not-allowed' : 'pointer'
                                            }}
                                            onClick={() => {
                                                if (!job.running) {
                                                    setSelectedJob(index)
                                                    setOpen(true)
                                                }
                                            }}
                                        >
                                            {job.running ? "Running" : "Trigger"}
                                        </Button>}
                                    </div>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {selectedJob !== undefined && <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <DialogTitle>
                        Run "{jobs[selectedJob].title}"
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Run the "{jobs[selectedJob].title}" module on the server or close the dialog to avoid executing the module.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Close</Button>
                        <Button onClick={() => {
                            setOpen(false)
                            socket.emit('runModule', jobs[selectedJob].title)
                        }} autoFocus>
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>}
            </div>
        </div>
    )
}
export default TriggerFire