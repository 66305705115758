import React, { useState } from 'react'
import { apiRequestGet } from '../utils/api'
import { SearchInput } from '../components/UsernameSearch/InputSearch'
import { GradientCircularProgress } from '../components/UsernameSearch/GradientCircularProgress'
import {
    Snackbar,
    AlertTitle,
    Alert,
} from '@mui/material'


const didIt = [
    "https://media.giphy.com/media/hguDE4wujlyUjJOt71/giphy.gif?cid=790b7611lklku6tm7873xnbl1fu7fbsxswqkqz0pnj0cq8q3&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    "https://media.giphy.com/media/26tPeuI0VimunEKdO/giphy.gif?cid=790b761108vb5ayumhf1p6roh42mq4rf1kpl21jlrgmw4uu8&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    "https://media.giphy.com/media/0CZsOACwOfLDFUc5p5/giphy.gif?cid=ecf05e47guzql4xfjzveilkc1k0gxscwda5s24ox9zc51x75&ep=v1_gifs_search&rid=giphy.gif&ct=g",
]
const didNot = [
    "https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExc3prZmdpcDdzMGoyMmUzOTYyN3EzZ2d6dDRkMHM2bGllMG5oNHlmOCZlcD12MV9naWZzX3NlYXJjaCZjdD1n/kcC51gk6idRgqV4Wn7/giphy.gif",
    "https://media.giphy.com/media/Sr9NHwRKlsD3unMK43/giphy.gif?cid=790b7611bk973hnzv321zsb0gfvgkrki67xpemk4j93x7djq&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    "https://media.giphy.com/media/GsS374LKHQYhuYs5ZP/giphy.gif?cid=790b7611bk973hnzv321zsb0gfvgkrki67xpemk4j93x7djq&ep=v1_gifs_search&rid=giphy.gif&ct=g",
]

export const SimonDidIt = () => {
    document.title = 'AutoIMDb | Did Simon Do It?'

    const [res, setRes] = useState(null)
    const [searchWord, setSearchWord] = useState("")
    const [loading, setLoading] = useState(false)

    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState(false)
    const [alertContent, setAlertContent] = useState("")

    const [selectedImage, setSelectedImage] = useState(0)

    const handleCloseAlertSnackbar = () => {
        setAlertSnackbarOpen(false)
    }

    const search = async () => {
        if (searchWord.trim() === "") {
            setAlertContent("Search field empty")
            setAlertSnackbarOpen(true)
            return
        }
        setLoading(true)
        try {
            const res = await apiRequestGet(`/did-simon-to-it?id=${searchWord}`)
            if (res.error !== undefined) {
                setAlertContent(res.error)
                setAlertSnackbarOpen(true)
            } else {
                if (res.didIt) setSelectedImage(Math.floor(Math.random() * didIt.length))
                else setSelectedImage(Math.floor(Math.random() * didNot.length))
                setRes(res.didIt)
            }
        } catch (_) { }
        setLoading(false)
    }

    const [searchButtonHovered, setSearchButtonHovered] = useState(false)

    return (
        <div style={{ display: 'inline-block', width: '100%', minHeight: 'calc(100vh - 64px)' }}>
            <div style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                backgroundRepeat: 'repeat-y',
                color: '#8d9399',
                padding: '10px 0px',
                boxSizing: 'border-box',
                overflowWrap: 'anywhere',
                width: '100%',
                height: '100%',
            }}>
                <div className="searchbar-settings">
                    <div className="searchbar-outline">
                        <SearchInput searchWord={searchWord} setSearchWord={setSearchWord} search={search} placeholder={`IMDb ID`} />
                        <span
                            style={{
                                height: 'calc(100% - 26px)',
                                backgroundColor: searchButtonHovered ? '#545759' : '#676c70',
                                right: 0,
                                position: 'absolute',
                                top: '13px',
                                width: '60px',
                                borderRadius: '0 35px 35px 0',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={(e) => { setSearchButtonHovered(true) }}
                            onMouseLeave={(e) => { setSearchButtonHovered(false) }}
                            onClick={search}
                        />
                        <span
                            style={{
                                backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAARdJREFUOBHNkz1uwkAQhTGkhCuELlQUiAtwilzHtU8BVLkHF4iokbJJkS41EiTS5nvL2FrQGMsdIz3N7ntvZn+8HgyyiDEuwBv4BifLmi8yW/sQ4xMowRl4Ib4Eo/YuKGYixT9QgSUYW9ZcvKJsbYSo42hFmVeeUbzp8vnHRNAdKCqvSc1JTy78NXeVEXWxiuWVcDORnlz4b6TLFFFfRzF2DUaiT5IrxqPnG0L+mDDzDBn3YuNDxjVDNdrZ7LVh/UGtv7sy2+3z1X7xz91GIhFLoOh6R+vWJtao62VrgW1aKsZP8rSrYf6vHSnYgw1IxyEHoAjgfrN7K6kYBKAI4DGbfbCzZ72j3lEUxRdFKxB6F3sF2okg7R/6f6gee6OuegAAAABJRU5ErkJggg==")',
                                right: '10px',
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                width: '50px',
                                height: '50px',
                                backgroundPosition: '50% 50%',
                                color: '#8d9399',
                                opacity: 1,
                                position: 'absolute',
                                backgroundRepeat: 'no-repeat',
                                transform: 'scale(1)',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={(e) => { setSearchButtonHovered(true) }}
                            onMouseLeave={(e) => { setSearchButtonHovered(false) }}
                            onClick={search}
                        />

                        <span
                            className="searchbar-start-icon"
                            style={{
                                backgroundImage: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAARdJREFUOBHNkz1uwkAQhTGkhCuELlQUiAtwilzHtU8BVLkHF4iokbJJkS41EiTS5nvL2FrQGMsdIz3N7ntvZn+8HgyyiDEuwBv4BifLmi8yW/sQ4xMowRl4Ib4Eo/YuKGYixT9QgSUYW9ZcvKJsbYSo42hFmVeeUbzp8vnHRNAdKCqvSc1JTy78NXeVEXWxiuWVcDORnlz4b6TLFFFfRzF2DUaiT5IrxqPnG0L+mDDzDBn3YuNDxjVDNdrZ7LVh/UGtv7sy2+3z1X7xz91GIhFLoOh6R+vWJtao62VrgW1aKsZP8rSrYf6vHSnYgw1IxyEHoAjgfrN7K6kYBKAI4DGbfbCzZ72j3lEUxRdFKxB6F3sF2okg7R/6f6gee6OuegAAAABJRU5ErkJggg==")',
                                backgroundColor: 'rgba(0,0,0,0)',
                                width: '45px',
                                height: '45px',
                                backgroundPosition: '50% 50%',
                                color: '#8d9399',
                                opacity: .4,
                                position: 'absolute',
                                backgroundRepeat: 'no-repeat',
                                transform: 'scale(1)',
                            }}
                        />
                    </div>
                </div>
                {
                    loading ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <GradientCircularProgress size="15rem" />
                        </div> :
                        res === null ? <></> : (
                            res ?
                                <>
                                    <img
                                        src={didIt[selectedImage]}
                                        alt="Simon did do it"
                                        className="mx-auto"
                                    />
                                    <p className="text-white mx-auto text-center">Yes it was me</p>
                                </> :
                                <>
                                    <img
                                        src={didNot[selectedImage]}
                                        alt="Simon did not do it"
                                        className="mx-auto"
                                    />
                                    <p className="text-white mx-auto text-center">Not it was not me</p>
                                </>
                        )
                }
            </div>
            <Snackbar
                open={alertSnackbarOpen}
                onClose={handleCloseAlertSnackbar}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    severity="warning"
                    variant="filled"
                    onClose={handleCloseAlertSnackbar}
                >
                    <AlertTitle>Error</AlertTitle>
                    {alertContent}
                </Alert>
            </Snackbar>
        </div>
    )
}