import React from 'react'
import { Grid, Box } from '@mui/material'
import EpisodeLineChart from '../components/Stats/EpisodesChart'
import EpisodeDonutChart from '../components/Stats/EpisodeDonutChart'
import ContributionsBarChart from '../components/Stats/ContributionsBarChart'
import { BarChartCard } from '../components/Cards/BarChartCard'
import { LineChartCard } from '../components/Cards/LineChartCard'

export const StatBoard = ({ coverage_data, data }) => {
    document.title = 'AutoIMDb | Stats'

    return (
        <div className="w-full inline-block box-border" style={{ minHeight: 'calc(100vh - 64px)' }}>
            <div className="w-full h-full bg-repeat-y" style={{
                background: '#132030',
                backgroundImage: "url('https://pub.dev/static/hash-1n1fj3ji/img/hero-bg-static.svg')",
                backgroundSize: '100% auto',
                color: '#8d9399',
                padding: '10px 0px',
                overflowWrap: 'anywhere',
            }}>
                <Grid container columns={2} spacing={1} className="total_board outer-page p-4">
                    {/* Uploaded / Accepted per day of current month */}
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                        <Box
                            className="w-full h-full"
                            sx={{
                                backgroundColor: 'rgb(33, 49, 60)',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                            }}
                        >
                            <BarChartCard data={data.contributions_data} />
                        </Box>
                    </Grid>
                    {/* Accepted, Uploaded and New Series by month-year */}
                    <ContributionsBarChart coverage_data={coverage_data} />

                    {/* Amount of episodes uploaded / not all time line chart */}
                    <EpisodeLineChart coverage_data={coverage_data} />
                    {/* Amount of episodes uploaded / not all time donut chart */}
                    <EpisodeDonutChart coverage_data={coverage_data} />

                    {/* Unprocessed / Outside SLA for current month */}
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                        <Box
                            className="w-full h-full"
                            sx={{
                                backgroundColor: 'rgb(33, 49, 60)',
                                padding: '1rem',
                                borderRadius: '0.5rem',
                            }}
                        >
                            <LineChartCard data={data.unprocessed_data} />
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
