import React from 'react'
import { Grid, Box, Chip, Avatar } from '@mui/material'
import './Styles/HoursWastedWatchingMovies.css'
import AnimatedNumber from 'react-animated-numbers'
import WastedImage from '../../resources/images/wasted2.png'

const HoursWastedWatchingMovies = ({ profile }) => {
    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box className="hours-wasted-box m-auto">
                <a
                    href={`https://imdb.com/user/${profile.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    id="badge-hours-wasted"
                >
                    <Chip
                        avatar={<Avatar src={profile.avatar_url} />}
                        label={profile.username}
                        variant="filled"
                        alt={`Avatar ${profile.username}`}
                        color="primary"
                        size="medium"
                        clickable={true}
                    />
                </a>
                <Grid
                    container
                    columns={3}
                    spacing={0}
                    style={{ width: '100%' }}
                >
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <h1 id="text-hours-wasted-h1">
                            <strong>
                                <AnimatedNumber
                                    includeComma
                                    animateToNumber={
                                        profile.ratings.hours_wasted_on_movies +
                                        profile.ratings.hours_wasted_on_episodes
                                    } // TODO: Remove if only movies
                                    duration={5000}
                                />
                            </strong>
                            <small>
                                <AnimatedNumber
                                    includeComma
                                    animateToNumber={Math.round(
                                        (profile.ratings
                                            .hours_wasted_on_movies +
                                            profile.ratings
                                                .hours_wasted_on_episodes) /
                                            24,
                                        0
                                    )} // TODO: Remove if only movies
                                    duration={5000}
                                />
                            </small>
                        </h1>
                        <img
                            src={WastedImage}
                            alt="Wasted watching movies!"
                            title="Wasted watching movies!"
                            className="inline-block"
                        />
                    </Grid>
                    <Grid item xs={3} sm={1} md={1} lg={1} xl={1}>
                        <h2 className="info-box-number">
                            <strong>
                                <AnimatedNumber
                                    includeComma
                                    animateToNumber={
                                        profile.ratings.ratings.length
                                    }
                                    duration={2000}
                                />
                            </strong>
                        </h2>
                        <p className="info-box-title">
                            <small>Ratings</small>
                        </p>
                    </Grid>
                    <Grid item xs={3} sm={1} md={1} lg={1} xl={1}>
                        <h2 className="info-box-number">
                            <strong>{profile.registration}</strong>
                        </h2>
                        <p className="info-box-title">
                            <small>Registered</small>
                        </p>
                    </Grid>
                    <Grid item xs={3} sm={1} md={1} lg={1} xl={1}>
                        <h2 className="info-box-number">
                            <strong>
                                <AnimatedNumber
                                    includeComma
                                    animateToNumber={
                                        profile.reviews.reviews.length
                                    }
                                    duration={2000}
                                />
                            </strong>
                        </h2>
                        <p className="info-box-title">
                            <small>Reviews</small>
                        </p>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default HoursWastedWatchingMovies
