import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { indexToMonth } from '../../../utils/dates.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const BarChartRatingsMadeByMonth = ({ profile }) => {
    if (
        profile.ratings === undefined ||
        profile.ratings.ratings === undefined ||
        profile.ratings.ratings.length === 0
    )
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    const months = {}
    for (let i = 0; i < 12; i++) months[i.toString()] = 0

    profile.ratings.ratings.forEach((rating) => {
        const date = new Date(rating.rated_at).getMonth()
        months[date.toString()]++
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            legend: { display: false },
                            title: {
                                display: true,
                                text: 'Ratings Made by Month',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')} - ${Math.round(
                                            1000 *
                                            (parseInt(yDatapoint.raw) /
                                                Math.max(
                                                    profile.titles_rated,
                                                    1
                                                )),
                                            0
                                        ) / 10
                                            }%`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: Object.keys(months).map((index) =>
                            indexToMonth(index)
                        ),
                        datasets: [
                            {
                                label: 'Ratings',
                                data: Object.values(months),
                                backgroundColor: [
                                    '#2d4e96', // WINTER
                                    '#2d4e96',
                                    '#388729', // SPRING
                                    '#388729',
                                    '#388729',
                                    '#FDFF00', // SUMMER
                                    '#FDFF00',
                                    '#FDFF00',
                                    '#d68915', // AUTUMN
                                    '#d68915',
                                    '#d68915',
                                    '#2d4e96', // WINTER
                                ],
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default BarChartRatingsMadeByMonth
