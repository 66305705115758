import React, { useEffect, useState } from 'react'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import { apiRequestPost } from '../../utils/api'
import { useCookies } from 'react-cookie'

const Settings = ({ setting }) => {
    document.title = 'AutoIMDb | Settings'

    const [email, setEmail] = useState(setting.email)
    const [username, setUsername] = useState(setting.username)
    const [cookies] = useCookies(['jwt'])

    const handleNotificationEmailChange = (event) => {
        setEmail(event.target.checked)
    }

    const handleUsernameChange = (event) => {
        setUsername(event.target.value)
    }

    useEffect(() => {
        async function updateSettings() {
            await apiRequestPost(
                '/update-settings',
                { email, username },
                cookies
            )
        }
        updateSettings()
    }, [email, username, cookies])

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
            }}
            className="outer-page"
        >
            <form>
                <Card>
                    <CardHeader
                        subheader="Manage the notifications"
                        title="Notifications"
                    />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={6} wrap="wrap">
                            <Grid
                                item
                                md={4}
                                sm={6}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                                xs={12}
                            >
                                <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="h6"
                                >
                                    Notifications
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={email}
                                            onChange={
                                                handleNotificationEmailChange
                                            }
                                        />
                                    }
                                    label="Email Contributions"
                                />
                                {email && (
                                    <FormControlLabel
                                        control={
                                            <TextField
                                                label="Username"
                                                variant="outlined"
                                                value={username}
                                                onChange={handleUsernameChange}
                                            />
                                        }
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </form>
        </Box>
    )
}

export default Settings
