import React from 'react'
import { Line } from 'react-chartjs-2'
import { Grid, Box } from '@mui/material'

const LineChartContributions = ({ profile }) => {
    // TODO: Add years from 2021 (Using profile.contributions.byYear)
    if (profile.contributions.placementsByTime === undefined)
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    className="m-auto"
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refetch data to view
                    </h3>
                </Box>
            </Grid>
        )

    const options = {
        responsive: true,
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: 'Total Contributions by Time',
                position: 'top',
            },
        },
    }
    const labels = profile.contributions.placementsByTime.map(
        (time) => time.time
    )
    const datasets = [
        {
            label: profile.username,
            data: profile.contributions.placementsByTime.map(
                (time) => time.items
            ),
            borderColor: 'lime',
        },
    ]

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                className="m-auto"
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                    position: 'relative',
                }}
            >
                <Line
                    options={options}
                    data={{
                        labels: labels,
                        datasets: datasets,
                    }}
                />
            </Box>
        </Grid>
    )
}
export default LineChartContributions
