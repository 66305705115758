import React, { useEffect, useState } from 'react'
import { apiRequestGet } from '../utils/api'
import { WatchlistComparison } from '../components/ProfileComparison/WatchlistComparison.js'
import { RatingComparison } from '../components/ProfileComparison/RatingComparison.js'
import { ProfileDialog } from '../components/ProfileComparison/ProfileDialog.js'
import '../CSS/ProfileComparison.css'
import { IndexDBGenericConnection } from '../utils/IndexDBConnection.js'
import { HeadlineComparison } from '../components/ProfileComparison/HeadlineComparison.js'
import RatingsByYear from '../components/ProfileComparison/RatingsByYear.js'
import RatingsByMonth from '../components/ProfileComparison/RatingsByMonth.js'
import MainstreamDivision from '../components/ProfileComparison/MainstreamDivision.js'
import RatingsByDistribution from '../components/ProfileComparison/RatingsByDistribution.js'
import { Grid } from '@mui/material'

const indexDb = new IndexDBGenericConnection()

export const ProfileComparison = () => {
    document.title = 'AutoIMDb | Profile Comparison'

    // * Holds all information about user
    const [profile1, setProfile1] = useState(undefined)
    const [profile2, setProfile2] = useState(undefined)

    const [comparison, setComparison] = useState(undefined)
    const [possible, setPossible] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [first, setFirst] = useState(true)
    const [currentAPITime, setCurrentAPITime] = useState(0)

    // * Fetches information about what users exist
    useEffect(() => {
        const fetchPossible = async () => {
            const pos = await apiRequestGet('/pre-processed-accounts')
            setCurrentAPITime(pos.currentTime)
            setPossible(pos)
        }
        fetchPossible()
    }, [])

    // * Fetches all information about user
    useEffect(() => {
        const fetchComparison = async () => {
            if (indexDb.connected) {
                const response = await indexDb.getData('comparison')

                if (response !== undefined && response.profile1 !== undefined)
                    setProfile1(response.profile1)
                if (response !== undefined && response.profile2 !== undefined)
                    setProfile2(response.profile2)

                setComparison(response)
            }
        }
        fetchComparison()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexDb.connected])

    useEffect(() => {
        if (
            comparison === undefined ||
            !comparison.ratings ||
            !indexDb.connected
        )
            return

        indexDb.setData('comparison', comparison)
    }, [comparison])

    // * Handle the fetching of data if it is missing
    useEffect(() => {
        if (
            !profile1 ||
            !profile2 ||
            !indexDb.connected ||
            comparison === undefined
        )
            return
        if (
            comparison &&
            comparison.profile1 &&
            profile1.id === comparison.profile1.id &&
            comparison.profile2 &&
            profile2.id === comparison.profile2.id
        )
            return

        const fetchComparison = async () => {
            setLoading(true)
            const response = await apiRequestGet(
                `/imdb-profile-comparison?profile1=${profile1.id}&profile2=${profile2.id}`
            )
            setComparison(response)
            setLoading(false)
        }
        fetchComparison()
    }, [comparison, profile1, profile2])

    useEffect(() => {
        if (!indexDb.connected || comparison === undefined) return
        if (!profile1) {
            setFirst(true)
            setOpen(true)
        } else if (!profile2) {
            setFirst(false)
            setOpen(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile1, profile2, indexDb, comparison])

    // * Dialog Component
    if (
        loading ||
        comparison === undefined ||
        !comparison.ratings ||
        !profile1 ||
        !profile2
    )
        return (
            <>
                <p>Loading...</p>
                <ProfileDialog
                    open={open}
                    setOpen={setOpen}
                    first={first}
                    selected1={profile1}
                    selected2={profile2}
                    possible={possible}
                    currentAPITime={currentAPITime}
                    setProfile1={setProfile1}
                    setProfile2={setProfile2}
                />
            </>
        )

    return (
        <div style={{ width: '100%' }} className="outer-page">
            <HeadlineComparison
                profile1={profile1}
                profile2={profile2}
                setFirst={setFirst}
                setOpen={setOpen}
            />

            <WatchlistComparison
                items={comparison.watchlist.watchTogether}
                profile1={profile1}
                profile2={profile2}
                profile1Watchlist={comparison.watchlist.profile1}
                profile2Watchlist={comparison.watchlist.profile2}
            />

            <RatingComparison
                headline="Titles you rated higher"
                ratings={comparison.ratings.higher}
                comparison={comparison}
            />
            <RatingComparison
                headline="Titles you rated lower"
                ratings={comparison.ratings.lower}
                comparison={comparison}
            />

            {/* Shown if user wants, or no profiles are selected */}
            <ProfileDialog
                open={open}
                setOpen={setOpen}
                first={first}
                selected1={profile1}
                selected2={profile2}
                possible={possible}
                currentAPITime={currentAPITime}
                setProfile1={setProfile1}
                setProfile2={setProfile2}
            />

            <Grid container columns={2} spacing={1}>
                <Grid item xs={2} md={2}>
                    <h4>Ratings Statistics</h4>
                </Grid>

                <RatingsByYear
                    ratings1={comparison.ratings.profile1Ratings}
                    ratings2={comparison.ratings.profile2Ratings}
                    profile1={profile1}
                    profile2={profile2}
                />

                <RatingsByMonth
                    ratings1={comparison.ratings.profile1Ratings}
                    ratings2={comparison.ratings.profile2Ratings}
                    profile1={profile1}
                    profile2={profile2}
                />

                <MainstreamDivision
                    ratings1={comparison.ratings.profile1Ratings}
                    ratings2={comparison.ratings.profile2Ratings}
                    profile1={profile1}
                    profile2={profile2}
                />

                <RatingsByDistribution
                    profile1RatingsByScore={comparison.ratings.profile1RatingsByScore}
                    profile2RatingsByScore={comparison.ratings.profile2RatingsByScore}
                    profile1={profile1}
                    profile2={profile2}
                />

                {/* <AverageDivision /> */}
            </Grid>
        </div>
    )
}
