import React, { useState } from 'react'
import { FaDiscord } from 'react-icons/fa'
import EmailIcon from '@mui/icons-material/Email'
import ForumIcon from '@mui/icons-material/Forum'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import InfoIcon from '@mui/icons-material/Info'
import PolicyIcon from '@mui/icons-material/Policy'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useLocation } from 'react-router-dom'

const PageSpeedDial = () => {
    const location = useLocation()

    // * Info section
    const actions = [
        { icon: <FaDiscord />, name: 'Discord', copy: 'Nomissimon10#6948' },
        {
            icon: <EmailIcon />,
            name: 'Email',
            link: 'mailto:contact@autoimdb.com',
            _blank: true,
        },
        {
            icon: <AccountCircleIcon />,
            name: 'IMDb Profile',
            link: 'https://www.imdb.com/user/ur105395432/',
            _blank: true,
        },
        {
            icon: <ForumIcon />,
            name: 'Forum Profile',
            link: 'https://community-imdb.sprinklr.com/users/60cddfccc394d5731d05fea3',
            _blank: true,
        },
        {
            icon: <PolicyIcon />,
            name: 'Privacy Policy',
            link: '/privacy-policy',
            _blank: false,
        },
        {
            icon: <InfoIcon />,
            name: 'Info',
            link: '/information',
            _blank: false,
        },
    ]

    const [copied, setCopied] = useState('')

    if (!['/contributors-top-placements', '/profile-viewer', '/contribution-log', '/profile-comparison', '/imdb-username-search', '/trigger-fire'].includes(location.pathname))
        return (
            <SpeedDial
                ariaLabel="Contacts"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<InfoIcon />}
            >
                {actions.map((action) =>
                    action.copy ? (
                        <CopyToClipboard
                            onCopy={() => setCopied(action.copy)}
                            key={`${action.name}-copy`}
                        >
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                title={
                                    copied === action.copy
                                        ? 'Copied'
                                        : action.name
                                }
                            />
                        </CopyToClipboard>
                    ) : action._blank ? (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            title={action.name}
                            href={action.link}
                            target="_blank"
                        />
                    ) : (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            title={action.name}
                            href={action.link}
                        />
                    )
                )}
            </SpeedDial>
        )
    else return null
}

export default PageSpeedDial
