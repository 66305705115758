import React from 'react'
import { Box, Grid } from '@mui/material'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const RatingsByYear = ({ ratings1, ratings2, profile1, profile2 }) => {
    if (ratings1 === undefined && ratings2 === undefined)
        return (
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                <Box
                    sx={{
                        backgroundColor: 'rgb(33, 49, 60)',
                        padding: '1rem',
                        width: 'calc(100% - 2rem)',
                        height: '100%',
                        borderRadius: '0.5rem',
                        position: 'relative',
                    }}
                >
                    <h3 style={{ color: 'white', textAlign: 'center' }}>
                        Data required, please refresh data to view
                    </h3>
                </Box>
            </Grid>
        )

    const years1 = {}
    const years2 = {}
    ratings1.forEach((rating) => {
        const date = new Date(rating.rated_at).getFullYear()
        if (Object.keys(years1).includes(date.toString()))
            years1[date.toString()]++
        else years1[date.toString()] = 1
    })
    ratings2.forEach((rating) => {
        const date = new Date(rating.rated_at).getFullYear()
        if (Object.keys(years2).includes(date.toString()))
            years2[date.toString()]++
        else years2[date.toString()] = 1
    })
    const years = [
        ...new Set([...Object.keys(years1), ...Object.keys(years2)]),
    ].sort((a, b) => a - b)

    years.forEach((year) => {
        if (!years1[year]) years1[year] = 0
        if (!years2[year]) years2[year] = 0
    })

    return (
        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
            <Box
                sx={{
                    backgroundColor: 'rgb(33, 49, 60)',
                    padding: '1rem',
                    width: 'calc(100% - 2rem)',
                    height: '100%',
                    borderRadius: '0.5rem',
                }}
            >
                <Bar
                    options={{
                        responsive: true,
                        plugins: {
                            title: {
                                display: true,
                                text: 'Ratings Made by Year',
                            },
                            tooltip: {
                                callbacks: {
                                    label: (yDatapoint) => {
                                        return `${parseInt(
                                            yDatapoint.raw
                                        ).toLocaleString('en-US')}`
                                    },
                                },
                            },
                        },
                    }}
                    data={{
                        labels: Object.keys(years1),
                        datasets: [
                            {
                                label: `${profile1.username}`,
                                data: Object.values(years1),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: `${profile2.username}`,
                                data: Object.values(years2),
                                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                            },
                        ],
                    }}
                />
            </Box>
        </Grid>
    )
}

export default RatingsByYear
