import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FaUserPlus, FaUserCheck } from 'react-icons/fa'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import HomeIcon from '@mui/icons-material/Home'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import PropTypes from 'prop-types'
import LogoutIcon from '@mui/icons-material/Logout'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import PieChartIcon from '@mui/icons-material/PieChart'
import LoginIcon from '@mui/icons-material/Login'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import PeopleIcon from '@mui/icons-material/People'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import ReorderIcon from '@mui/icons-material/Reorder'
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Link } from 'react-router-dom'
import { Drawer, Box, Divider, useMediaQuery, Typography } from '@mui/material'
import { NavItem } from './NavItem'
import AppIcon from '../../resources/images/logo.png'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

const Sidebar = ({ user, open, onClose }) => {
    const location = useLocation()
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false,
    })

    const items = [
        {
            path: '/',
            title: 'Dashboard',
            icon: <HomeIcon fontSize="small" />,
        },
        ...(user.isLoggedIn && user.role === 'admin'
            ? [
                {
                    path: '/new-podcast',
                    title: 'New Podcast',
                    icon: <CloudUploadIcon fontSize="small" />,
                },
            ]
            : []),
        ...(user.isLoggedIn && user.role === 'admin'
            ? [
                {
                    path: '/trigger-fire',
                    title: 'TriggerFire',
                    icon: <ScheduleSendIcon fontSize="small" />,
                },
            ]
            : []),
        {
            path: '/stats',
            title: 'Stats',
            icon: <PieChartIcon fontSize="small" />,
        },
        {
            path: '/contribution-log',
            title: 'Contribution Log',
            icon: <TextSnippetIcon fontSize="small" />,
        },
        {
            path: '/status',
            title: 'Status',
            icon: <MonitorHeartIcon fontSize="small" />,
        },
        ...(user.isLoggedIn
            ? [
                {
                    path: '/verify-podcasts',
                    title: 'Verify Podcasts',
                    icon: <FaUserCheck fontSize="small" />,
                },
                {
                    path: '/verify-shows',
                    title: 'Verify Shows',
                    icon: <FaUserCheck fontSize="small" />,
                },
            ]
            : []),
        {
            path: '/imdb-username-search',
            title: 'IMDb Username Search',
            icon: <PersonSearchIcon fontSize="small" />,
        },
        {
            path: '/profile-viewer',
            title: 'Profile Viewer',
            icon: <AccountCircleIcon fontSize="small" />,
        },
        {
            path: '/profile-comparison',
            title: 'Profile Comparison',
            icon: <PeopleIcon fontSize="small" />,
        },
        {
            path: '/contributors-top-placements',
            title: 'Top Placements',
            icon: <ReorderIcon fontSize="small" />,
        },
        {
            path: '/contributor-leaderboard',
            title: 'Contributors',
            icon: <LeaderboardIcon fontSize="small" />,
        },
        {
            path: '/top-10-contributors',
            title: 'Top 10 Contributors',
            icon: <WorkspacePremiumIcon fontSize="small" />,
        },
        {
            path: '/did-simon-do-it',
            title: 'Did Simon Do It?',
            icon: <ContactSupportIcon fontSize="small" />,
        },
        ...(!user.isLoggedIn
            ? [
                {
                    path: '/login',
                    title: 'Sign In',
                    icon: <LoginIcon fontSize="small" />,
                },
            ]
            : [
                {
                    path: '/logout',
                    title: 'Sign Out',
                    icon: <LogoutIcon fontSize="small" />,
                },
            ]),
        ...(!user.isLoggedIn
            ? [
                {
                    path: '/signup',
                    title: 'Sign Up',
                    icon: <FaUserPlus fontSize="small" />,
                },
            ]
            : []),
    ]

    useEffect(() => {
        if (!location.isReady) return

        if (open) onClose()
    }, [location.asPath, location.isReady, onClose, open])

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box sx={{ paddingTop: 3, paddingLeft: 3 }}>
                    <Link
                        to="/"
                        draggable={false}
                        style={{ textDecoration: 'none' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'revert',
                                verticalAlign: 'center',
                            }}
                        >
                            <img
                                src={AppIcon}
                                style={{
                                    height: 42,
                                    width: 42,
                                }}
                                draggable={false}
                                alt="Logo"
                            />
                            <Typography
                                variant="h4"
                                style={{
                                    color: 'white',
                                    fontSize: 'auto',
                                    textTransform: 'capitalize',
                                    marginLeft: '5px',
                                    verticalAlign: 'center',
                                }}
                            >
                                AutoIMDb
                            </Typography>
                        </div>
                    </Link>
                </Box>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3,
                    }}
                />
                <Box sx={{ flexGrow: 1 }}>
                    {items.map((item) => (
                        <NavItem
                            key={item.title}
                            icon={item.icon}
                            href={item.path}
                            title={item.title}
                        />
                    ))}
                </Box>
            </Box>
        </>
    )

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    )
}

export default Sidebar

Sidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
