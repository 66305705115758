import axios from 'axios'
import { io } from 'socket.io-client'

const API_URL = 'https://api.autoimdb.com/api'
// const API_URL = 'http://127.0.0.1:8055/api'

const app = axios.create({
    baseURL: API_URL,
    // withCredentials: true
})
app.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response.data.err)
)

export const apiRequestGet = async (endpoint) => {
    try {
        const res = (await app.get(endpoint)).data

        return res
    } catch (e) {
        return { error: 'An unknown error has occured' }
    }
}

export const apiRequestPost = async (endpoint, data, cookies) => {
    try {
        if (
            cookies.jwt !== undefined &&
            cookies.jwt !== 'undefined' &&
            cookies.jwt !== ''
        )
            data.jwt = cookies.jwt

        const res = (await app.post(`${endpoint}`, data)).data

        return res
    } catch (e) {
        console.log(e)
        return { error: 'An unknown error has occured' }
    }
}

export const socket = io('https://autoimdb.com:5600/', {
    autoConnect: true,
    reconnection: true,
    rejectUnauthorized: false,
})