import React from 'react'
import { Line } from 'react-chartjs-2'
import { Card, useTheme, Box, Skeleton, Stack } from '@mui/material'

export const LineChartCard = ({ data }) => {
    const theme = useTheme()

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]
    let labels = []
    let datasets = []
    let datasetLabels = []
    if (data && data.labels && data.datasets && data.datasetLabels) {
        labels = data.labels.map(
            (date) =>
                `${new Date(date).getDate()} ${
                    monthNames[new Date(date).getMonth()]
                }`
        )
        datasets = data.datasets
        datasetLabels = data.datasetLabels
    }

    const finalDatasets = []
    const colors = ['#196EE6', '#16CC62']
    datasets.forEach((dataset, index) => {
        finalDatasets.push({
            label: datasetLabels[index],
            data: dataset,
            fill: false,
            backgroundColor: colors[index],
            borderColor: colors[index],
            cubicInterpolationMode: 'monotone',
        })
    })

    const dataLineChart = {
        labels: labels,
        datasets: finalDatasets,
    }

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: true },
        maintainAspectRatio: false,
        responsive: true,
        xAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    fontColor: theme.palette.text.secondary,
                    beginAtZero: true,
                    min: 0,
                },
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider,
                },
            },
        ],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
            callbacks: {
                label: function (tooltipItem, data) {
                    return `${
                        data.datasets[tooltipItem.datasetIndex].label
                    }: ${tooltipItem.yLabel
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                },
            },
        },
    }

    return (
        <Card>
            <Box
                sx={{
                    height: 400,
                    position: 'relative',
                    backgroundColor: 'rgb(33, 49, 60)',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    padding: '1rem',
                }}
            >
                {data && data.labels && data.datasets && data.datasetLabels ? (
                    <Line data={dataLineChart} options={options} />
                ) : (
                    <Stack
                        style={{
                            margin: 'auto',
                            paddingTop: 35,
                            height: 330,
                            width: 'auto',
                        }}
                        spacing={1}
                    >
                        <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: '1rem', width: 'auto', height: 40 }}
                        />
                        <Skeleton
                            variant="circular"
                            animation="pulse"
                            width={50}
                            height={50}
                        />
                        <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width={'auto'}
                            height={240}
                        />
                    </Stack>
                )}
            </Box>
        </Card>
    )
}
